<section class="careers" *ngIf="data && data?.header">
  <article class="claim">
    <div class="claim__bg" [ngStyle]="{'background-image': 'url(' + data?.header?.background_img + ')'}"></div>
    <div class="claim__inner">
      <h1 class="claim__title">
        <span>{{ data?.header?.title?.part_1 }}</span>
        <span>&nbsp;</span>
        <strong>{{ data?.header?.title?.bold_part_2 }}</strong>
      </h1>
    </div>
  </article>

  <div class="positions" *ngIf="data?.content">
    <div class="positions__inner">
      <h3 class="positions__title">
        {{ data?.content?.title }}
      </h3>
      <section class="positions__wrapper" *ngIf="jobs">
        <article class="position" *ngFor="let job of jobs">
          <p class="position__date">{{ job?.DATE }}</p>
          <p class="position__place">{{ job?.CITY }}</p>
          <h2 class="position__title mb-1">{{ job?.TITLE }}</h2>
          <p class="position__description">
            {{ job?.DESCRIPTION?.part_1 | slice:0:250 }}...
          </p>
          <button class="link" (click)="openJobDetails(job?.SLUG, $event)" title="Open Job Details">
            <span class="link__text">{{ job?.LINK_TEXT }}</span>
          </button>

          <!-- Aqui modal -->
          <section id="modal_job_{{ job?.SLUG }}" class="modal" *ngIf="job?.data">
            <div class="modal-content">
              <button type="button" name="button_close_job" (click)="closeJobDetails(job?.SLUG, $event)" class="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>

              <h2 class="modal__title">{{ job?.data?.title }}</h2>
              <p class="modal__location mb-5">
                <img src="../../../assets/icons/location-icon.png" class="modal__location__img" alt="Location Image"/>
                <span>{{ job?.data?.location?.city }}, {{ job?.data?.location?.country }}</span>
              </p>

              <article class="modal__text">
                <p *ngIf="job?.data?.date" class="mb-2">
                  <strong>Date created:</strong>
                  <span>&nbsp;</span>
                  <span>{{ job?.data?.date }}</span>
                </p>
                <p *ngIf="job?.data?.type" class="mb-2">
                  <strong>Type:</strong>
                  <span>&nbsp;</span>
                  <span>{{ job?.data?.type }}</span>
                </p>
                <p *ngIf="job?.data?.accountable_to" class="mb-2">
                  <strong>Accountable to:</strong>
                  <span>&nbsp;</span>
                  <span>{{ job?.data?.accountable_to }}</span>
                </p>
                <p *ngIf="job?.data?.people_manager" class="mb-2">
                  <strong>People Manager:</strong>
                  <span>&nbsp;</span>
                  <span>{{ job?.data?.people_manager }}</span>
                </p>

                <p *ngIf="job?.data?.description?.part_1" class="mb-2 mt-5">
                  <strong>Summary of Role:</strong>
                </p>

                <p *ngIf="job?.data?.description?.part_1" class="mb-2">
                  {{ job?.data?.description?.part_1 }}
                </p>
                <hr />
                <div *ngIf="job?.data?.description?.part_2" class="mb-2" [innerHTML]="job?.data?.description?.part_2"></div>
                <p *ngIf="job?.data?.description?.part_3" class="mb-2">
                  {{ job?.data?.description?.part_3 }}
                </p>

                <!-- Responsability Main -->
                <p *ngIf="job?.data?.description_key_responsibilities_text" class="mb-2 mt-5">
                  <strong>{{ job?.data?.description_key_responsibilities_text }}</strong>
                </p>

                <ul *ngIf="job?.data?.responsabilityList">
                  <li *ngIf="job?.data?.description_key_responsibilities_list?.list_item_1_text">
                    <span>{{ job?.data?.description_key_responsibilities_list?.list_item_1_text }}</span>
                    <ul *ngIf="job?.data?.responsabilityListItem1">
                      <div *ngFor="let item of job?.data?.responsabilityListItem1">
                        <li *ngIf="item && item?.constructor?.name == 'String'">
                          {{ item }}
                        </li>
                      </div>
                    </ul>
                  </li>
                  <li *ngIf="job?.data?.description_key_responsibilities_list?.list_item_2_text">
                    <span>{{ job?.data?.description_key_responsibilities_list?.list_item_2_text }}</span>
                    <ul *ngIf="job?.data?.responsabilityListItem2">
                      <div *ngFor="let item of job?.data?.responsabilityListItem2">
                        <li *ngIf="item && item?.constructor?.name == 'String'">
                          {{ item }}
                        </li>
                      </div>
                    </ul>
                  </li>

                  <div *ngFor="let item of job?.data?.responsabilityList">
                    <li *ngIf="item && item?.constructor?.name == 'String'">
                      {{ item }}
                    </li>
                  </div>
                </ul>

                <p *ngIf="job?.data?.respAloneItem">{{ job?.data?.respAloneItem }}</p>

                <!-- Competencies Main -->
                <p *ngIf="job?.data?.description_role_competencies_text" class="mb-2">
                  <strong>{{ job?.data?.description_role_competencies_text }}</strong>
                </p>

                <ul *ngIf="job?.data?.competenciesList" class="mb-5">
                  <div *ngFor="let item of job?.data?.competenciesList">
                    <li *ngIf="item && item?.constructor?.name == 'String'">
                      {{ item }}
                    </li>
                  </div>
                </ul>

                <!-- Competencies List 1 -->
                <p *ngIf="job?.data?.description_role_competencies_text?.list_item_text" class="mb-2">
                  {{ job?.data?.description_role_competencies_text?.list_item_text }}
                </p>

                <ul *ngIf="job?.data?.competenciesListItem1" class="mb-5">
                  <div *ngFor="let item of job?.data?.competenciesListItem1">
                    <li *ngIf="item && item?.constructor?.name == 'String'">
                      {{ item }}
                    </li>
                  </div>
                </ul>

                <!-- Competencies Behavioural -->
                <p *ngIf="job?.data?.competenciesBehaviouralText" class="mb-2">
                  {{ job?.data?.competenciesBehaviouralText }}
                </p>

                <ul *ngIf="job?.data?.competenciesBehavioural" class="mb-5">
                  <div *ngFor="let item of job?.data?.competenciesBehavioural">
                    <li *ngIf="item && item?.constructor?.name == 'String'">
                      {{ item }}
                    </li>
                  </div>
                </ul>

                <!-- Competencies Technical -->
                <p *ngIf="job?.data?.competenciesTechnicalText" class="mb-2">
                  {{ job?.data?.competenciesTechnicalText }}
                </p>

                <ul *ngIf="job?.data?.competenciesTechnical" class="mb-5">
                  <div *ngFor="let item of job?.data?.competenciesTechnical">
                    <li *ngIf="item && item?.constructor?.name == 'String'">
                      {{ item }}
                    </li>
                  </div>
                </ul>

                <!-- Requirements Main -->
                <ul *ngIf="job?.data?.list" class="mb-5">
                  <div *ngFor="let item of job?.data?.list">
                    <li *ngIf="item && item?.constructor?.name == 'String'">
                      <span>{{ item }}</span>
                    </li>
                    <li *ngIf="item && item?.constructor?.name == 'Object' && (item?.item_1 != '' || item?.text != '')">
                    <span *ngIf="item?.item_1 && item?.item_1?.constructor?.name == 'String'">
                      {{ item?.item_1 }}
                      <br/>
                    </span>
                      <span *ngIf="item?.item_2 && item?.item_2?.constructor?.name == 'String'">
                      {{ item?.item_2 }}
                        <br/>
                    </span>
                      <span *ngIf="item?.item_3 && item?.item_3?.constructor?.name == 'String'">
                      {{ item?.item_3 }}
                        <br/>
                    </span>
                      <span *ngIf="item?.text && item?.text?.constructor?.name == 'String'">
                      {{ item?.text }}
                    </span>
                    </li>
                  </div>
                </ul>

                <h3 class="modal__subtitle mb-1">{{ job?.data?.apply_now_text }}</h3>

                <p *ngIf="job?.data?.send_email_text?.red_email_part_2">
                  <span>{{ job?.data?.send_email_text?.part_1 }}</span>
                  <span>&nbsp;</span>
                  <a href="mailto:{{ job?.data?.send_email_text?.red_email_part_2 }}" class="link" rel="noopener noreferrer" title="Send Email">
                  <span class="link__text">
                    {{ job?.data?.send_email_text?.red_email_part_2 }}
                  </span>
                  </a>
                </p>

                <p *ngIf="job?.data?.visit_our_linkedin" class="mb-2">
                  <a class="link" target="_blank" href="{{ job?.data?.visit_our_linkedin.link }}" rel="noopener noreferrer" title="Open Link">
                  <span class="link__text">
                    {{ job?.data?.visit_our_linkedin?.hyperlink_text }}
                  </span>
                  </a>
                </p>

                <p>CNP Santander is an equal opportunities employer.</p>
              </article>
            </div>
          </section>

        </article>
      </section>
      <a href="#" (click)="showAll($event)"
         [ngClass]="{'d-none': hideShowMoreLink }" class="link positions__show-all" title="Show all jobs" rel="noopener noreferrer">
        <span class="link__text">{{ data?.content?.show_all_link_text }}</span>
      </a>
    </div>
  </div>
</section>
