import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class PageService
{

  constructor(
      private api: ApiService
  ) {}

  getPage(page: string): Observable<any>
  {
    return this.api.get('md.php?page=' + page + '&type=simple-page');
  }

}
