import { Injectable } from '@angular/core';

declare var Vimeo;
declare var OneTrust;
declare var BrowseAloud;
declare var window;
declare var MapMarkers;

@Injectable({
    providedIn: 'root'
})
export class HelperProvider
{

    public getRouteLink(item: any)
    {
        if (typeof item === 'object') {
            if (item.type_label) {
                if (item.type_label.toLowerCase() === 'covid19') {
                    return '/covid19/' + item.object_slug;
                }

                if (item.type.toLowerCase() === 'custom' && this.isValidHttpUrl(item.url)) {
                    return item.url;
                }
            }

            return '/' + item.object_slug;
        }

        return '/' + item;
    }

    public getTargetRouteLink(item: any)
    {
        if (typeof item === 'object') {
            if (item.hasOwnProperty('target') && item.target) {
                return item.target;
            }
        }

        return '_self';
    }

    public isValidHttpUrl(data)
    {
        let url;

        try {
            url = new URL(data);
        } catch (_) {
            return false;
        }

        return url.protocol === 'http:' || url.protocol === 'https:';
    }

    public getLengthObjectKeys(object: {})
    {
        if (typeof object === 'object' && object !== undefined && object !== null) {
            return Object.keys(object).length;
        }

        return 0;
    }

    public getVimeoIdFromUrl(url: string = '')
    {
        const match = /vimeo.*\/(\d+)/i.exec(url);

        if (match) {
            return match[1];
        }

        return false;
    }

    public loadVimeo(container, videoId)
    {
        let player = null;
        if (container) {
            const options = {
                id: parseInt(videoId, 0),
                width: 640,
                loop: false,
                dnt: 1
            };

            player = new Vimeo.Player(container, options);
        }

        return player;
    }

    getExtensionFromString(value: string)
    {
        return value.substr(value.lastIndexOf('.') + 1);
    }

    capitalize(s)
    {
        return s && s[0].toUpperCase() + s.slice(1);
    }

    async loadReachDeckScript()
    {
        const isMobile = window.innerWidth <= 800;
        if (!isMobile) {
            window._baFixedNav = ['header'];
        }

        if (window.location.hostname === 'localhost') {
            return false;
        }

        const reachDeck = this.getReachDeck();

        return reachDeck ? Promise.resolve( reachDeck ) : new Promise( (resolve, reject) => {

            const SRC = 'https://www.browsealoud.com/plus/scripts/3.1.0/ba.js';
            const INTEGRITY = 'sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ==';

            try {
                const findScript = () => document.querySelector<HTMLScriptElement>(`script[src^="${SRC}"]`);
                const injectScript = () => {

                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    // tslint:disable-next-line:no-shadowed-variable
                    const script = document.createElement('script');

                    script.src = SRC;
                    script.type = 'text/javascript';
                    script.defer = true;
                    script.async = true;
                    script.integrity = INTEGRITY;
                    script.crossOrigin = 'anonymous';

                    (document.body).appendChild(script);

                    return script;
                };

                const script = findScript() || injectScript();

                script.addEventListener('error', (error) => {
                    reject( new Error('Unable to load Reach Deck plugin') );
                });
                script.addEventListener('load', () => resolve( this.getReachDeck() ));
            } catch (e) { reject(e); }
        });

    }


    reachDeckBottomToolbar()
    {
        const bottomNavId = 'footer';
        const showHide = () => {
            const isMobile = window.innerWidth <= 800;
            // tslint:disable-next-line:no-shadowed-variable
            const fixedNav = document.getElementById(bottomNavId);
            let show = false;
            if (isMobile) {
                const toolbar = document.getElementById('__bs_entryDiv')?.lastElementChild?.shadowRoot || null;
                if (toolbar !== null) {
                    const childs = Array.from(toolbar.childNodes);
                    // loop toolbar div to see if toolbar is active
                    // tslint:disable-next-line:prefer-for-of
                    for (let t = 0; t < childs.length && !show; t++) {
                        show = false;
                        const currentChild = childs[t];
                        if (currentChild['id'] === 'th_toolbar'){
                            show = true;
                        }
                    }
                }
            }

            if (fixedNav) {
                fixedNav.style.bottom = show ? '60px' : '';
            }
        }
        // function to set a listener on the toolbar element for opening and closing
        const setToolbarListener = () => {
            const checkForToolbar = setInterval(() => {
                const toolbar = document.getElementById('__bs_entryDiv')?.lastElementChild?.shadowRoot || null;
                if (toolbar !== null) {
                    // Options for the observer (which mutations to observe)
                    const config = { childList: true };
                    // Create an observer instance linked to the callback function
                    const observer = new MutationObserver(showHide);
                    // Start observing the target node for configured mutations
                    observer.observe(toolbar, config);
                    clearInterval(checkForToolbar);
                }
            },100);
        }

        // load listener to apply the other listeners on the toolbar
        window.addEventListener('load', () => {
            const checkBA = setInterval(() => {
                // tslint:disable-next-line:triple-equals
                if(typeof(BrowseAloud) != 'undefined'){
                    showHide();
                    setToolbarListener();
                    clearInterval(checkBA);
                }
            },100);
        });

        window.addEventListener('resize', showHide);
    }

    getReachDeck()
    {
        return window && window.hasOwnProperty('ReachDeck') || window.hasOwnProperty('BrowseAloud');
    }


    listenOneTrustChanged()
    {
        document.addEventListener('OneTrust_Initialized', (data) => {
            OneTrust.OnConsentChanged((event: any) => {
                if (event.type === 'consent.onetrust') {
                    const detail = event.detail; // "C0001" necesarias, "C0003" funcionales y no obligatorias
                    if (Array.isArray(detail)) {
                        if (typeof BrowseAloud !== 'undefined') {
                            // si no está C0003 en detail desactivamos, en caso contrario activamos.
                            if (detail.indexOf('C0003') > -1) {
                                BrowseAloud.enableBrowsealoudAnalytics();
                            } else {
                                BrowseAloud.disableBrowsealoudAnalytics();
                            }
                        }
                    }
                }
            });
        });
    }

    disabledBrowsealoudAnalytics()
    {
        if (typeof BrowseAloud !== 'undefined') {
            BrowseAloud.disableBrowsealoudAnalytics();
        }
    }


    refreshReachDeckToolbar()
    {
        if (typeof BrowseAloud !== 'undefined') {
            if (BrowseAloud.isToolbarActive()) {
                try {
                    BrowseAloud.panel.toggleBar(!0); // off
                    BrowseAloud.panel.toggleBar(!0); // on
                    const n = setInterval((() =>  {
                        const entryDiv = document.getElementById('__bs_entryDiv');
                        if (entryDiv) {
                            entryDiv.firstElementChild.shadowRoot.getElementById('th_play').click();
                            clearInterval(n);
                        }
                    }), 500)
                } catch (e) {
                    console.log('error refreshReachDeckToolbar');
                    console.log(e);
                }
            }
        }
    }

    listenClickEventSimplifyReachDeck()
    {
        if (typeof BrowseAloud !== 'undefined') {
            try {
                const n = setInterval((() =>  {
                    const entryDiv = document.getElementById('__bs_entryDiv');
                    if (entryDiv) {
                        const thSimplify = entryDiv.firstElementChild.shadowRoot.getElementById('th_simplify');
                        if (thSimplify) {
                            thSimplify.addEventListener('click', (e) => {
                                e.preventDefault();
                                const stylesId = document.title.toLowerCase();
                                if (!thSimplify.classList.contains('gw-toolbar-button--toggle-state')) {
                                    // no está activo, agregamos css de cada pagina

                                    const baSimplifyPane = entryDiv.firstElementChild.shadowRoot.getElementById('ba-simplify-pane');
                                    const staticMap = entryDiv.firstElementChild.shadowRoot.getElementById('staticmap');

                                    /*
                                    if (staticMap) {
                                        // clonamos contenido para agregar nuevo css y hacer un refresh
                                        const styleTag = document.createElement('style');
                                        baSimplifyPane.appendChild(styleTag);

                                        const css = 'p{color:red !important;}';
                                        if (styleTag.style){
                                            styleTag.style.cssText = css;
                                        } else {
                                            styleTag.appendChild(document.createTextNode(css));
                                        }
                                        styleTag.id = stylesId;
                                    }
                                     */
                                } else {
                                    const styleAppend = entryDiv.firstElementChild.shadowRoot.getElementById(stylesId);
                                    if (styleAppend) {
                                        styleAppend.remove()
                                    }
                                }
                            })
                        }
                        clearInterval(n);
                    }
                }), 500)
            } catch (e) {
                console.log('error refreshReachDeckToolbar');
                console.log(e);
            }
        }
    }

    setButtonNameGoogleMaps() {
        const googleMap = document.getElementById('google-map');

        if (googleMap) {
            const gmStyle = googleMap.getElementsByClassName('gm-style');
            if (gmStyle.length > 0) {
                const buttons = gmStyle[0].querySelectorAll('button');
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < buttons.length; i++) {
                    const button = buttons[i];
                    if (!button.name) {
                        button.name = ((new Date()).getTime() + i).toString();
                    }
                }
            }
        }
    }

    getGoogle()
    {
        return window && window.hasOwnProperty('google');
    }

    loadGoogleMaps()
    {
        const google = this.getGoogle();

        return google ? Promise.resolve( google ) : new Promise( (resolve, reject) => {

            const SRC = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDqMLsjV03bTt3u3fO4u0QJeqpIOzjp7c4&language=en&callback=googleMapLoaded';

            try {
                const findScript = () => document.querySelector<HTMLScriptElement>(`script[src^="${SRC}"]`);
                const injectScript = () => {

                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    // tslint:disable-next-line:no-shadowed-variable
                    const script = document.createElement('script');

                    script.src = SRC;
                    script.type = 'text/javascript';
                    script.defer = true;
                    script.async = true;
                    script.crossOrigin = 'anonymous';

                    (document.body).appendChild(script);

                    return script;
                };

                const script = findScript() || injectScript();

                script.addEventListener('error', (error) => {
                    reject(new Error('Unable to load google maps'));
                });
                script.addEventListener('load', () => resolve(this.getGoogle()));
            } catch (e) {
                reject(e);
            }
        });
    }

    getMapMarkers()
    {
        return typeof MapMarkers !== 'undefined';
    }

    loadMapMarkersScript()
    {
        const mapMarkers = this.getMapMarkers();

        return mapMarkers ? Promise.resolve( mapMarkers ) : new Promise( (resolve, reject) => {

            const SRC = '../assets/scripts/map-markers.js';

            try {
                const findScript = () => document.querySelector<HTMLScriptElement>(`script[src^="${SRC}"]`);
                const injectScript = () => {

                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    // tslint:disable-next-line:no-shadowed-variable
                    const script = document.createElement('script');

                    script.src = SRC;
                    script.type = 'text/javascript';
                    script.defer = true;
                    script.async = true;

                    (document.body).appendChild(script);

                    return script;
                };

                const script = findScript() || injectScript();

                script.addEventListener('error', (error) => {
                    reject(new Error('Unable to load map markers'));
                });
                script.addEventListener('load', () => resolve(this.getMapMarkers()));
            } catch (e) {
                reject(e);
            }
        });
    }

    buildMapMarkers(mapDataJSON)
    {
        const name = 'staticmap';
        window[name] = new MapMarkers(name, mapDataJSON);
        window[name].importJSON(mapDataJSON);
        window[name].showAllMapMarkers();
        window[name].loadEvents();
    }

}
