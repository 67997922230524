<section class="productsListTemplate">
  <div class="hero">
    <header class="hero__bg" [ngStyle]="{'background-image': 'url(' + data?.header?.background_img + ')'}">
      <h1 class="hero__title">{{ data?.header?.title }}</h1>
    </header>
    <article class="hero__content">
        <div class="hero__content__text">
            <p>
              <strong>
                  <em class="red">{{ data?.header?.subtitle?.red_part_1 }}</em>
                <span>&nbsp;</span>
                <span>{{ data?.header?.subtitle?.part_2 }}</span>
              </strong>
            </p>
            <p class="small">
              <span>For</span>
              <span>&nbsp;</span>
              <strong>sales enquiries</strong>
              <span>&nbsp;</span>
              <span>please contact your local Santander representative.</span>
            </p>
        </div>
    </article>
  </div>

  <nav class="anchor-menu">
    <section class="anchor-menu__inner">
      <a href="#" (click)="scrollTo('p0', $event)"
         class="anchor-menu__item btn btn--grey-negative" title="Scroll to Lifestyle Protection Insurance Product" rel="noopener noreferrer">
        Lifestyle Protection Insurance
      </a>
      <a href="#" (click)="scrollTo('p1', $event)"
         class="anchor-menu__item btn btn--grey-negative" title="Scroll to Guaranteed Asset Protection Insurance Product" rel="noopener noreferrer">
          Guaranteed Asset Protection Insurance
      </a>
      <a href="#" (click)="scrollTo('p2', $event)"
         class="anchor-menu__item btn btn--grey-negative" title="Scroll to Credit Protection Insurance Product" rel="noopener noreferrer">
        Credit Protection Insurance
      </a>
    </section>
  </nav>

  <section *ngFor="let product of productList; let i=index; let odd=odd; let even=even;">
    <article class="product p{{ i }}" id="p{{ i }}" [ngClass]="{ odd: odd, even: even}" [ngStyle]="{'background-image': 'url(' + product?.BACKGROUND_IMG_URL + ')'}">
      <section class="product__content">
        <h2 class="product__content__title">
          <strong>{{ product?.TITLE_BOLD_PART_1 }}</strong>
          <span>&nbsp;</span>
          <span>{{ product?.TITLE_PART_2 }}</span>
        </h2>
        <div class="product__content__text">
          <p>
            <em>{{ product?.SUBTITLE_RED_PART_1 }}</em>
            <span>&nbsp;</span>
            <span>{{ product?.SUBTITLE_PART_2 }}</span>
          </p>
        </div>
        <a [routerLink]="['/products',  product?.SLUG ]" class="btn btn--grey" title="Go To Product" rel="noopener noreferrer">
          <span>{{ product?.BUTTON_TEXT }}</span>
          <span>&nbsp;</span>
          <img src="../assets/icons/plus-grey-icon.png" class="btn__asset" alt="Icon plus grey" style="width: 36px; height: 35px;">
          <img src="../assets/icons/plus-white-icon.png" class="btn__asset btn__asset--hover" alt="Icon plus white" style="width: 36px; height: 35px;">
        </a>
      </section>
    </article>
  </section>

</section>
