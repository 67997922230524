<section class="legal" *ngIf="data">
  <header class="legal__heading">
    <h1 class="legal__heading__inner legal__heading__title">{{ data?.header?.title }}</h1>
  </header>

  <article class="legal__content">
    <p class="legal__content__text">
      <span>{{ data?.content?.text_1?.part_1?.part_1_1 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_1?.part_1?.part_1_2_website }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_1?.part_1?.part_1_3 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_1?.part_2 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_1?.part_3?.part_3_1 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_1?.part_3?.part_3_2_website }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_1?.part_3?.part_3_3 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_1?.part_4 }}</span>
      <span>&nbsp;</span>
      <strong>{{ data?.content?.text_1?.part_5?.bold_part_5_1 }}</strong>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_1?.part_5?.part_5_2 }}</span>
      <span>&nbsp;</span>
      <strong>{{ data?.content?.text_1?.part_6?.bold_part_6_1 }}</strong>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_1?.part_6?.part_6_2 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_1?.part_7 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_1?.part_8 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_1?.part_9 }}</span>
      <span>&nbsp;</span>
    </p>
    <h2 class="legal__content__title">{{ data?.content?.text_2?.title }}</h2>
    <p class="legal__content__text">{{ data?.content?.text_2?.text }}</p>
    <h2 class="legal__content__title">{{ data?.content?.text_3?.title }}</h2>
    <p class="legal__content__text">
      <span>{{ data?.content?.text_3?.text?.part_1 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_3?.text?.part_2 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_3?.text?.part_3?.part_3_1 }}</span>
      <span>&nbsp;</span>
      <a [routerLink]="['/privacy-policy']" class="link" title="Privacy Policy" rel="noopener noreferrer">
        <span class="link__text">{{ data?.content?.text_3?.text?.part_3?.part_3_2_red_link }}</span>
      </a>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_3?.text.part_3.part_3_3 }}</span>
    </p>

    <h2 class="legal__content__title">{{ data?.content?.text_4?.title }}</h2>
    <p class="legal__content__text">
      {{ data?.content?.text_4?.text }}
    </p>

    <h2 class="legal__content__title">{{ data?.content?.text_5?.title }}</h2>
    <p class="legal__content__text">
      <span>{{ data?.content?.text_5?.text_2 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_5?.text_3 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_5?.text_4 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_5?.text_5_1 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_5?.text_5_5 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_5?.text_5_6 }}</span>
    </p>

    <ul class="legal__content__text">
      <li *ngFor="let item of list51">{{ item?.text }}</li>
    </ul>

    <ul class="legal__content__text">
      <li *ngFor="let item of list52">{{ item?.text }}</li>
    </ul>


    <h2 class="legal__content__title">{{ data?.content?.text_6?.title }}</h2>
    <p class="legal__content__text">
      <span>{{ data?.content?.text_6?.text_6_1 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_6?.text_6_2 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_6?.text_6_3 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_6?.text_6_4 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_6?.text_6_5 }}</span>
    </p>

    <ul class="legal__content__text">
      <li *ngFor="let item of list61">{{ item?.text }}</li>
    </ul>

    <h2 class="legal__content__title">{{ data?.content?.text_7?.title }}</h2>
    <p class="legal__content__text">
      <span>{{ data?.content?.text_7?.text_7_1 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_7?.text_7_2 }}</span>
    </p>

    <h2 class="legal__content__title">{{ data?.content?.text_8?.title }}</h2>
    <p class="legal__content__text">
      <span>{{ data?.content?.text_8?.text_8_1 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_8?.text_8_2 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_8?.text_8_3 }}</span>
    </p>

    <h2 class="legal__content__title">{{ data?.content?.text_9?.title }}</h2>
    <p class="legal__content__text">
      <span>{{ data?.content?.text_9?.text_9_1 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_9?.text_9_2 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_9?.text_9_3 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_9?.text_9_4 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_9?.text_9_5?.part_1 }}</span>
      <span>&nbsp;</span>
      <a [routerLink]="['/privacy-policy']" class="link" title="Privacy Policy" rel="noopener noreferrer">
        <span class="link__text">{{ data?.content?.text_9?.text_9_5?.part_2_red_link }}</span>
      </a>
    </p>

    <h2 class="legal__content__title">{{ data?.content?.text_10?.title }}</h2>
    <p class="legal__content__text">
      {{ data?.content?.text_10?.text }}
    </p>

    <h2 class="legal__content__title">{{ data?.content?.text_11?.title }}</h2>
    <p class="legal__content__text">
      <span>{{ data?.content?.text_11?.text_11_1 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_11?.text_11_2 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_11?.text_11_3 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_11?.text_11_4 }}</span>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_11?.text_11_5 }}</span>
    </p>

    <h2 class="legal__content__title">{{ data?.content?.text_12?.title }}</h2>
    <p class="legal__content__text">
      <span>{{ data?.content?.text_12?.text?.part_1 }}</span>
      <span>&nbsp;</span>
      <a [routerLink]="['/privacy-policy']" class="link" title="Privacy Policy" rel="noopener noreferrer">
        <span class="link__text">{{ data?.content?.text_12?.text?.part_2_red_link }}</span>
      </a>
      <span>&nbsp;</span>
      <span>{{ data?.content?.text_12?.text?.part_3 }}</span>
    </p>

    <h2 class="legal__content__title">{{data?.content?.text_13?.title }}</h2>
    <p class="legal__content__text">
      <span>{{ data?.content?.text_13?.text?.part_1 }}</span>
      <span>&nbsp;</span>
      <a href="mailto:{{ data?.content?.text_13?.text?.part_2_red_email }}" class="link" title="Email" rel="noopener noreferrer">
        <span class="link__text">{{ data?.content?.text_13?.text?.part_2_red_email }}</span>
      </a>
    </p>

  </article>
</section>
