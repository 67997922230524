<section class="distributor distributor--right-top">
    <div class="distributor__inner">
        <div class="distributor__content">
            <div *ngIf="data?.content?.title" [innerHTML]="data?.content?.title"></div>
            <div *ngIf="data?.content?.text" [innerHTML]="data?.content?.text"></div>
        </div>
        <div class="distributor__image">
            <div class="gapcalculator">
                <div class="gapcalculator__content">
                    <div class="gapcalculator__title" [innerHTML]="data?.form?.title"></div>
                    <div class="gapcalculator__form">
                        <form novalidate>
                            <div class="">
                                <div class="row">
                                    <div class="col-12" [innerHTML]="data?.form?.fields?.block_1?.title"></div>
                                    <div class="col-6 col-xs-12">
                                        <div class="input-container">
                                            <input [(ngModel)]="calculator.gross_amount" #gross_amount="ngModel" name="gross_amount"
                                                (blur)="checkEnabledForm($event)"
                                                class="input_text" type="number"
                                                [placeholder]="data?.form?.fields?.block_1?.field_1?.placeholder"
                                                required="required">
                                            <label class="has-toltip">
                                                {{ data?.form?.fields?.block_1?.field_1?.label }}
                                                <span class="">
                                                    <svg width="12" height="12">
                                                        <use xlink:href="../../../assets/images/icon-info.svg#info"></use>
                                                    </svg>
                                                </span>
                                                <span class="toltip">
                                                    <span class="toltip-title">Lorem ipsum dolor sit</span>
                                                    <span class="toltip-content">Amet consectetur adipisicing elit. Quidem at, quae atque omnis asperiores nulla assumenda enim voluptatibus placeat nesciunt eos doloribus a ex facilis maiores sequi in fugit repellendus!</span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6 col-xs-12">
                                        <div class="input-container">
                                            <input [(ngModel)]="calculator.net_amount" #net_amount="ngModel"
                                                (blur)="checkEnabledForm($event)"
                                                name="net_amount" class="input_text" type="number"
                                                [placeholder]="data?.form?.fields?.block_1?.field_2?.placeholder" required="required">
                                            <label class="has-toltip">
                                                {{ data?.form?.fields?.block_1?.field_2?.label }}
                                                <span class="">
                                                    <svg width="12" height="12">
                                                        <use xlink:href="../../../assets/images/icon-info.svg#info"></use>
                                                    </svg>
                                                </span>
                                                <span class="toltip">
                                                    <span class="toltip-title">Lorem ipsum dolor sit</span>
                                                    <span class="toltip-content">Amet consectetur adipisicing elit. Quidem at, quae atque omnis asperiores nulla assumenda enim voluptatibus placeat nesciunt eos doloribus a ex facilis maiores sequi in fugit repellendus!</span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-6">
                                        <label class="has-toltip">
                                            <span>{{ data?.form?.fields?.block_1?.field_3?.label }}</span>
                                            <span class="">
                                                <svg width="12" height="12">
                                                    <use xlink:href="../../../assets/images/icon-info.svg#info"></use>
                                                </svg>
                                            </span>
                                            <span class="toltip">
                                                <span class="toltip-title">Lorem ipsum dolor sit</span>
                                                <span class="toltip-content">Amet consectetur adipisicing elit. Quidem at, quae atque omnis asperiores nulla assumenda enim voluptatibus placeat nesciunt eos doloribus a ex facilis maiores sequi in fugit repellendus!</span>
                                            </span>
                                        </label>
                                        <div class="radio-inline">
                                            <input  [(ngModel)]="calculator.children" #children="ngModel" name="children"
                                                    (change)="checkEnabledForm($event)"
                                                    [id]="data?.form?.fields?.block_1?.field_3?.options?.option_1"
                                                    type="radio"
                                                    [value]="true" required="required">
                                            <label class="" [for]="data?.form?.fields?.block_1?.field_3?.options?.option_1">
                                                {{ data?.form?.fields?.block_1?.field_3?.options?.option_1 }}
                                            </label>

                                            <input  [(ngModel)]="calculator.children" #children="ngModel" name="children"
                                                    (change)="checkEnabledForm($event)"
                                                    [id]="data?.form?.fields?.block_1?.field_3?.options?.option_2"
                                                    type="radio"
                                                    [value]="false">
                                            <label class=""  [for]="data?.form?.fields?.block_1?.field_3?.options?.option_2">
                                                {{ data?.form?.fields?.block_1?.field_3?.options?.option_2 }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <label class="has-toltip">
                                            <span>{{ data?.form?.fields?.block_1?.field_4?.label }}</span>
                                            <span class="">
                                                <svg width="12" height="12">
                                                    <use xlink:href="../../../assets/images/icon-info.svg#info"></use>
                                                </svg>
                                            </span>
                                            <span class="toltip">
                                                <span class="toltip-title">Lorem ipsum dolor sit</span>
                                                <span class="toltip-content">Amet consectetur adipisicing elit. Quidem at, quae atque omnis asperiores nulla assumenda enim voluptatibus placeat nesciunt eos doloribus a ex facilis maiores sequi in fugit repellendus!</span>
                                            </span>
                                        </label>
                                        <div class="radio-inline">
                                            <input [(ngModel)]="calculator.state" #state="ngModel" name="state"
                                                   (change)="checkEnabledForm($event)"
                                                    [id]="data?.form?.fields?.block_1?.field_4?.options?.option_1"
                                                   type="radio"
                                                   [value]="true"  required="required">
                                            <label class="" [for]="data?.form?.fields?.block_1?.field_4?.options?.option_1">
                                                {{ data?.form?.fields?.block_1?.field_4?.options?.option_1 }}
                                            </label>

                                            <input [(ngModel)]="calculator.state" #state="ngModel" name="state"
                                                   (change)="checkEnabledForm($event)"
                                                    [id]="data?.form?.fields?.block_1?.field_4?.options?.option_2"
                                                   type="radio"
                                                   [value]="false">
                                            <label class="" [for]="data?.form?.fields?.block_1?.field_4?.options?.option_2">
                                                {{ data?.form?.fields?.block_1?.field_4?.options?.option_2 }}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <!-- bloque 2-->
                                <div [class]="(disabledForm) ? 'row mt-4 disabled' : 'row mt-4'">
                                    <div class="col-12" [innerHTML]="data?.form?.fields?.block_2?.title"></div>
                                    <div id="ttd_calculations" class="col-6 col-xs-12">
                                        <div class="card">
                                            <div class="card-content p-3">
                                                <div class="input">
                                                    <div class="card-icon">
                                                        <svg width="28" height="26">
                                                            <use xlink:href="../../../assets/images/icon-cash.svg#cash"></use>
                                                        </svg>
                                                    </div>
                                                    <label class="has-toltip">
                                                        <span>{{ data?.form?.fields?.block_2?.field_1?.top?.label }}</span>
                                                        <span class="">
                                                            <svg width="12" height="12">
                                                                <use xlink:href="../../../assets/images/icon-info.svg#info"></use>
                                                            </svg>
                                                        </span>
                                                        <span class="toltip">
                                                            <span class="toltip-title">Lorem ipsum dolor sit</span>
                                                            <span class="toltip-content">Amet consectetur adipisicing elit. Quidem at, quae atque omnis asperiores nulla assumenda enim voluptatibus placeat nesciunt eos doloribus a ex facilis maiores sequi in fugit repellendus!</span>
                                                        </span>
                                                    </label>
                                                    <img *ngIf="data?.form?.fields?.block_2?.field_1?.top?.icon" [src]="data?.form?.fields?.block_2?.field_1?.top?.icon" alt="Image"/>
                                                    <input *ngIf="ttdGap" class="input-card card-value red" type="text" name="yes_ttdGap" [value]="ttdGap" disabled>
                                                    <input *ngIf="!ttdGap" class="input-card" type="text" name="no_ttdGap" value="" [placeholder]=" data?.form?.fields?.block_2?.field_1?.top?.placeholder" disabled>
                                                </div>
                                                <div class="input">
                                                    <div class="card-icon">
                                                        <svg width="28" height="28">
                                                            <use xlink:href="../../../assets/icons/icon-doctor.svg#doctor"></use>
                                                        </svg>
                                                    </div>
                                                    <label>{{ data?.form?.fields?.block_2?.field_1?.bottom?.label }}</label>
                                                    <img *ngIf="data?.form?.fields?.block_2?.field_1?.bottom?.icon" [src]="data?.form?.fields?.block_2?.field_1?.bottom?.icon" alt="Image"/>
                                                    <input *ngIf="sickPayBenefit" class="input-card blue" name="yes_sickPayBenefit" type="text" [value]="sickPayBenefit" disabled>
                                                    <input *ngIf="!sickPayBenefit" class="input-card" name="no_sickPayBenefit" type="text" [placeholder]=" data?.form?.fields?.block_2?.field_1?.bottom?.placeholder" disabled>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="iu_calculations" class="col-6 col-xs-12">
                                        <div class="card">
                                            <div class="card-content p-3">
                                                <div class="input">
                                                    <div class="card-icon">
                                                        <svg width="28" height="26">
                                                            <use xlink:href="../../../assets/images/icon-cash.svg#cash"></use>
                                                        </svg>
                                                    </div>
                                                    <label class="has-toltip">
                                                        <span>{{ data?.form?.fields?.block_2?.field_2?.top?.label }}</span>
                                                        <span class="">
                                                            <svg width="12" height="12">
                                                                <use xlink:href="../../../assets/images/icon-info.svg#info"></use>
                                                            </svg>
                                                        </span>
                                                        <span class="toltip">
                                                            <span class="toltip-title">Lorem ipsum dolor sit</span>
                                                            <span class="toltip-content">Amet consectetur adipisicing elit. Quidem at, quae atque omnis asperiores nulla assumenda enim voluptatibus placeat nesciunt eos doloribus a ex facilis maiores sequi in fugit repellendus!</span>
                                                        </span>
                                                    </label>
                                                    <img *ngIf="data?.form?.fields?.block_2?.field_2?.top?.icon" [src]="data?.form?.fields?.block_2?.field_2?.top?.icon" alt="Image"/>
                                                    <input *ngIf="iuGap" class="input-card card-value red" name="yes_iuGap" type="text" [value]="iuGap" disabled>
                                                    <input *ngIf="!iuGap" class="input-card" type="text" name="no_iuGap" [placeholder]="data?.form?.fields?.block_2?.field_2?.top?.placeholder" disabled>
                                                </div>
                                                <div class="input">
                                                    <div class="card-icon">
                                                        <svg width="25" height="20">
                                                            <use xlink:href="../../../assets/images/icon-suitcase.svg#suitcase"></use>
                                                        </svg>
                                                    </div>
                                                    <label>{{ data?.form?.fields?.block_2?.field_2?.bottom?.label }}</label>
                                                    <img *ngIf="data?.form?.fields?.block_2?.field_2?.bottom?.icon" [src]="data?.form?.fields?.block_2?.field_2?.bottom?.icon" alt="Image"/>
                                                    <input *ngIf="unemploymentBenefit" class="input-card blue" name="yes_unemploymentBenefit" type="text" [value]="unemploymentBenefit" disabled>
                                                    <input *ngIf="!unemploymentBenefit" class="input-card" name="no_unemploymentBenefit" type="text" [placeholder]=" data?.form?.fields?.block_2?.field_2?.bottom?.placeholder" disabled>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="data?.form?.fields?.button?.text && data?.form?.fields?.button?.link" class="row mt-4">
                                    <div class="col-12">
                                        <a class="btn btn-danger btn-lg btn-block btn-radius" [href]="data?.form?.fields?.button?.link" [title]="data?.form?.fields?.button?.link" target="_blank" rel="noopener noreferrer">
                                            {{ data?.form?.fields?.button?.text }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="gapcalculator__information" [innerHTML]="data?.form?.information"></div>
        </div>
    </div>
</section>
