import { Component, OnInit, HostListener, Inject } from '@angular/core';
import {HomeService} from '../../services/home/home.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  public data;

  constructor(private homeService: HomeService) { }

  ngOnInit()
  {
    this.homeService.getHeader().subscribe(menu => {
      console.log("MENU", menu);
      // this.data = JSON.parse(menu.acf);
      this.data = (menu.length > 0) ? menu[0].acf : menu.acf;
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    const element = document.getElementById('header');
    if (element) {
      if (window.pageYOffset > 150) {
        element.classList.add('header--sticky');
      } else {
        element.classList.remove('header--sticky');
      }
    }
  }

  closeMobileMenu() {
    const element = document.getElementById('header');
    element.classList.remove('header--openMob');
  }

  toggleMenuMob(event)
  {
    event.preventDefault();
    const element = document.getElementById('header');
    if ( element.classList.contains('header--openMob') ) {
      element.classList.remove('header--openMob');
    } else {
      element.classList.add('header--openMob');
    }
  }

  getLengthObjectKeys(object: {})
  {
    if (typeof object === 'object' && object !== undefined && object !== null) {
      return Object.keys(object).length;
    }

    return 0;
  }

  responsiveDropdown($event: any)
  {
    $event.preventDefault();
    if ( window.innerWidth < 993 ) {
      const fromElement = $event.srcElement.parentNode.classList.contains('dropdown') ? $event.srcElement : $event.srcElement.parentNode;
      console.log(fromElement);
      fromElement.parentNode.classList.contains('hover') ? fromElement.parentNode.classList.remove('hover') : fromElement.parentNode.classList.add('hover');
    }
  }
}
