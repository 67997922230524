<div class="text-cta text-cta--image text-cta--light" [ngStyle]="{'background-image': 'url(../assets/images/bg-component-jobs02.jpg)'}">
  <div class="text-cta__inner">
    <h2 class="text-cta__title">Do you share the same values?</h2>
    <div class="text-cta__text">
      <p>
        Visit our jobs section to find out more about what it's like to work at CNP Santander Insurance.
      </p>
    </div>
    <!-- Contrast Failure ↓ -->
    <a [routerLink]="['/jobs']" class="btn btn--red" title="Jobs" rel="noopener noreferrer">
        View Jobs
      <img src="../assets/icons/right-white-icon.svg" class="btn__asset" alt="Icon Right">
    </a>
  </div>
</div>
