import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService
{

  constructor(private api: ApiService) {}

  getHome(): Observable<any>
  {
    return this.api.get('md.php?page=home&type=pages');
  }

  getFooterText(): Observable<any>
  {
    return this.api.get('md.php?page=menu-footer-items&type=pages');
  }

  getHeader(): Observable<any>
  {
    return this.api.get('md.php?page=menuheader&type=pages');
  }

  getMenuFooterNew(): Observable<any>
  {
    return this.api.get('md.php?page=menu-footer&type=pages');
  }

  getHeaderNew(isoCode: any = null): Observable<any>
  {
    return this.api.get('md.php?page=menu-top&type=pages');
  }

}
