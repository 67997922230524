<header id="header" class="header" (scroll)="onWindowScroll($event);" *ngIf="data">
  <nav class="header__inner">
    <h1>
      <a class="mainLogo" [routerLink]="['home']" title="Home" rel="noopener noreferrer">
        <img *ngIf="data?.logo_santander" src="{{ data?.logo_santander }}" alt="Logo CNP Santander">
      </a>
    </h1>
    <div class="nav">
      <ul class="mainHeaderNav">
        <!-- Contrast Failure ↓  (when "a" element is active)-->
        <li><a [routerLink]="['/home']" routerLinkActive="active" (click)="closeMobileMenu()" title="Home" rel="noopener noreferrer">{{ data?.link_home }}</a></li>
        <li><a [routerLink]="['/aboutus']" routerLinkActive="active" (click)="closeMobileMenu()" title="About Us" rel="noopener noreferrer">{{ data?.link_about_us }}</a></li>
        <li><a [routerLink]="['/news']" routerLinkActive="active" (click)="closeMobileMenu()" title="News" rel="noopener noreferrer">{{ data?.link_corporate_news }}</a></li>
        <li><a [routerLink]="['/products']" routerLinkActive="active" (click)="closeMobileMenu()" title="Products" rel="noopener noreferrer">{{ data?.link_products }}</a></li>
        <li><a [routerLink]="['/careershome']" routerLinkActive="active" (click)="closeMobileMenu()" title="Careers" rel="noopener noreferrer">{{ data?.link_careers }}</a></li>
        <li><a [routerLink]="['/contact']" routerLinkActive="active" (click)="closeMobileMenu()" title="Contact" rel="noopener noreferrer">{{ data?.link_contact }}</a></li>
        <li><a [routerLink]="['/covid19/en']" routerLinkActive="active" (click)="closeMobileMenu()" title="Covid 19" rel="noopener noreferrer">{{ data?.covid }}</a></li>
        <li class="dropdown" style="margin-right: 0">
          <a href="#" (click)="responsiveDropdown($event)" title="Dropdown Countries" rel="noopener noreferrer">
            <span>{{ data?.select_country }}</span>
            <img class="dropdown__asset" src="../../../assets/icons/down-icon.png" alt="Icon dropdown" />
          </a>
          <ul class="dropdown-content">
            <div *ngFor="let item of [].constructor(getLengthObjectKeys(data?.langs)); let i = index">
              <li *ngIf="data?.langs['lang_' + (i+1)].url" >
                <a [href]="data?.langs['lang_' + (i+1)].url" target="_blank" title="{{ data?.langs['lang_' + (i+1)].text }}" rel="noopener noreferrer">
                  {{ data?.langs['lang_' + (i+1)].text }}
                </a>
              </li>
            </div>
          </ul>
        </li>
      </ul>

    </div>
      <a class="socialLinks" href="https://www.linkedin.com/company/cnp-santander-insurance/" target="_blank" title="Go to Linkedin" rel="noopener noreferrer">
          <img src="{{ data?.logo_linkedin }}" alt="Logo Linkedin">
      </a>
      <button type="button" name="btn-mobile" class="btn-mobile" (click)="toggleMenuMob($event)" aria-label="Mobile Button">
        <span [hidden]="true">Toggle</span>
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </button>
  </nav>

</header>
