import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../services/home/home.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit
{
  public data;

  constructor(private homeService: HomeService) { }

  ngOnInit()
  {
    this.homeService.getFooterText().subscribe((menu: any) => {
      this.data = (menu.length > 0) ? menu[0].acf : menu.acf;
    });
  }
}
