import { Component, OnInit } from '@angular/core';
import { AboutService } from '../../services/about/about.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about-us03',
  templateUrl: './about-us03.component.html',
  styleUrls: ['./about-us03.component.scss']
})
export class AboutUs03Component implements OnInit
{
  public data;

  constructor(
      private aboutService: AboutService,
      private meta: Meta,
      private titleService: Title
  ) {}

  ngOnInit()
  {
    this.aboutService.getAbout(3).subscribe(about => {
      this.data = (about.length > 0) ? about[0].acf : about.acf;
      if (this.data.hasOwnProperty('meta_tag_title')) {
        this.titleService.setTitle(this.data.meta_tag_title.content ?? 'About Us');
      }
      if (this.data.hasOwnProperty('meta_tag_description')) {
        this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
      }
    });
  }

}
