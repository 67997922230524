import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { NewsService } from '../../services/news/news.service';
import { ActivatedRoute } from '@angular/router';
import {DomSanitizer, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-news02',
  templateUrl: './news02.component.html',
  styleUrls: ['./news02.component.scss']
})
export class News02Component implements OnInit
{
  public data;
  public slug;
  private id;
  current;
  newsList;
  prevId = 2;
  prevNew;
  nextId = 5;
  nextNew;

  constructor(
      private newsService: NewsService,
      private route: ActivatedRoute,
      private titleService: Title,
      public domSanitizer: DomSanitizer,
      public ref: ChangeDetectorRef
  ) {}

  ngOnInit()
  {
    this.route.params.subscribe(params => {
      this.slug = params['id'];
      this.id = parseInt(this.slug.replace('news', ''), 10);

      this.titleService.setTitle('News');

      this.newsService.getAllNews().subscribe((news: any) => {
        this.data = (news.length > 0) ? news.reverse() : [];

        this.calcPrevNext();
      });
    });
  }


  calcPrevNext()
  {
    let currentIndex = null;
    this.current = this.data.find(newInfo => newInfo.slug === this.slug);
    this.prevNew = false;
    this.nextNew = false;
    this.newsList = this.data;

    this.newsList.map((item, index) => {
      if (item.slug === this.slug) {
        currentIndex = index;
      }
      return item;
    });

    const idActual = currentIndex;

    if (this.current && this.current.hasOwnProperty('acf')) {
      const acf = { ...this.current.acf.content, has_next: this.current.acf.has_next};
      delete this.current.acf;
      this.current = {...this.current, ...acf};
    }

    if (this.current && this.current.has_next === true ) {
      this.nextId = idActual + 1;
      this.data.map((item, index) => {
        if (this.nextId === index) {
          this.nextNew = item;
        }
        return item;
      });
    }

    if (idActual >= 0 ) {
      this.prevId = idActual - 1;
      this.data.map((item, index) => {
        if (this.prevId === index) {
          this.prevNew = item;
        }
        return item;
      });
    }

    this.newsList = this.data.filter((item, index) => item.slug !== this.slug);
    this.newsList = this.newsList.filter((item, index) => index < 2);

    // this.ref.detectChanges();
  }


  sanitizerHtml(html: any)
  {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

}
