import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutUsComponent } from './views/about-us/about-us.component';
import { AboutUs02Component } from './views/about-us02/about-us02.component';
import { AboutUs03Component } from './views/about-us03/about-us03.component';
import { AboutUs04Component } from './views/about-us04/about-us04.component';
import { ProductsListComponent } from './views/products-list/products-list.component';
import { ProductComponent } from './views/product/product.component';
import { CategoryComponent } from './views/category/category.component';
import { HomeComponent } from './views/home/home.component';
import { ReportsComponent } from './views/reports/reports.component';
import { ContactComponent } from './views/contact/contact.component';
import { Careers01Component } from './views/careers01/careers01.component';
import { Careers02Component } from './views/careers02/careers02.component';
import { Careers03Component } from './views/careers03/careers03.component';
import { Careers04Component } from './views/careers04/careers04.component';
import { Careers05Component } from './views/careers05/careers05.component';
import { LegalComponent } from './views/legal/legal.component';
import { News01Component } from './views/news01/news01.component';
import { News02Component } from './views/news02/news02.component';

import { CovidPostComponent} from './views/covidPost/covidPost.component';

import { TermsComponent } from './views/terms/terms.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { JobDetailComponent } from './components/job-detail/job-detail.component';
import { PrivacyPoliciesComponent } from './views/privacy-policies/privacy-policies.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { PageComponent } from './views/page/page.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'aboutus', component: AboutUsComponent},
  { path: 'missionvision', component: AboutUs02Component},
  { path: 'ourvalues', component: AboutUs03Component},
  { path: 'corporateculture', component: AboutUs04Component},
  { path: 'products', component: ProductsListComponent},
  { path: 'products/:id', component: ProductComponent},
  { path: 'careershome', component: Careers01Component },
  { path: 'careers/webelieveinyou', component: Careers02Component },
  { path: 'jobs', component:  Careers03Component },
  { path: 'careers/workwithus', component: Careers04Component },
  { path: 'careers/careers05', component: Careers05Component },
  { path: 'careers/job-detail', component: JobDetailComponent },
  { path: 'category', component: CategoryComponent},
  { path: 'news', component: News01Component },
  { path: 'corporate-news/:id', component: News02Component },
  { path: 'covid19/:id', component: CovidPostComponent },
  { path: 'reports', component: ReportsComponent},
  { path: 'contact', component: ContactComponent },
  { path: 'legal', component: LegalComponent },
  { path: 'termsandconditions', component: TermsComponent },
  { path: 'privacy-policy', component: PrivacyComponent },
  { path: 'privacy-policies', component: PrivacyPoliciesComponent },
  { path: 'p/:id', component: PageComponent },
  { path: '**', component: PageNotFoundComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
