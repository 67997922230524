<section class="contact" *ngIf="data">
    <section *ngIf="data?.header?.text"  class="medium-title">
      <article class="medium-title__inner">
        <h1 class="medium-title__title">{{ data?.header?.text }}</h1>
      </article>
    </section>
    <section id="staticmap">
        <article class="map__mapContainer">
            <section class="map__mapHolder">
                <figure>
                    <img src="../assets/images/maps/static-map-europe.png" alt="Map" />
                </figure>
            </section>
        </article>
        <article class="map__inner">
            <app-contact-products [data]="currentLocation" (infoClosed)="closedWindowHandler($event)"></app-contact-products>
        </article>
    </section>

    <section class="text-image text-image--reverse">
      <div class="text-image__inner">
        <article class="text-image__content">
          <h2 *ngIf="data?.content?.headquarters_box?.title?.part_1" class="text-image__content__title">
              <span>{{ data?.content?.headquarters_box?.title?.part_1 }}</span>
              <span>&nbsp;</span>
              <strong>{{ data?.content?.headquarters_box?.title?.bold_part_2 }}</strong>
          </h2>
          <div class="text-image__content__text">
            <ul>
                <li *ngIf="data?.content?.headquarters_box?.text?.bold_part_1">
                    <strong>{{ data?.content?.headquarters_box?.text?.bold_part_1 }}</strong>
                </li>
                <li *ngIf="data?.content?.headquarters_box?.text?.bold_part_2">
                    <strong>{{ data?.content?.headquarters_box?.text?.bold_part_2 }}</strong>
                </li>
                <li *ngIf="data?.content?.headquarters_box?.text?.part_3">
                    {{ data?.content?.headquarters_box?.text?.part_3 }}
                </li>
                <li *ngIf="data?.content?.headquarters_box?.text?.part_4">
                    {{ data?.content?.headquarters_box?.text?.part_4 }}
                </li>
                <li *ngIf="data?.content?.headquarters_box?.text?.part_5">
                    {{ data?.content?.headquarters_box?.text?.part_5 }}
                </li>
                <li *ngIf="data?.content?.headquarters_box?.text?.part_6">
                    {{ data?.content?.headquarters_box?.text?.part_6 }}
                </li>
                <li *ngIf="data?.content?.headquarters_box?.text?.part_7">
                    {{ data?.content?.headquarters_box?.text?.part_7 }}
                </li>
                <li *ngIf="data?.content?.headquarters_box?.text?.part_8">
                    {{ data?.content?.headquarters_box?.text?.part_8 }}
                </li>
            </ul>
          </div>
        </article>
        <article *ngIf="data?.content?.headquarters_box?.img" class="text-image__image">
          <figure>
              <img src="{{ data?.content?.headquarters_box?.img }}" class="text-image__image__img" alt="Image">
          </figure>
        </article>
      </div>
    </section>
    
</section>
