<section class="ouraspirations">
  <div class="ouraspirations__inner">
    <header class="ouraspirations__heading">
      <h2 class="ouraspirations__heading__title">{{ data?.title }}</h2>
      <p class="ouraspirations__heading__text">
          <span>{{ data?.text?.part_1 }}</span>
          <span>&nbsp;</span>
          <strong> {{ data?.text?.bold_part_2 }}</strong>
          <span>&nbsp;</span>
          <span>{{ data?.text?.part_3 }}</span>
      </p>
    </header>
    <!-- GRAY BOXES -->
    <!-- GRAY BOX 1 -->
    <section class="ouraspirations__wrap">
        <article class="ouraspirations__block">
          <div class="ouraspirations__block__content">
            <h3 class="ouraspirations__block__content__title">
                <span>{{ data?.dark_gray_box_1?.title?.red_part_1 }}</span>
                <span>&nbsp;</span>
                <span><strong>{{ data?.dark_gray_box_1?.title?.bold_red_part_2 }}</strong></span>
            </h3>
            <p class="ouraspirations__block__content__text">{{ data?.dark_gray_box_1?.text }}</p>
          </div>
        </article>
      <!-- GRAY BOX 2 -->
        <article class="ouraspirations__block">
          <div class="ouraspirations__block__content">
            <h3 class="ouraspirations__block__content__title">
                <span>{{ data?.light_gray_box_2?.title?.red_part_1 }}</span>
                <span>&nbsp;</span>
                <strong>{{ data?.light_gray_box_2?.title?.bold_red_part_2 }}</strong>
            </h3>
            <p class="ouraspirations__block__content__text">{{ data?.light_gray_box_2?.text }}</p>
          </div>
        </article>
      <!-- GRAY BOX 3 -->
        <article class="ouraspirations__block">
          <div class="ouraspirations__block__content">
            <h3 class="ouraspirations__block__content__title">
                <span>{{ data?.dark_gray_box_3?.title?.red_part_1 }}</span>
                <span>&nbsp;</span>
                <strong>{{ data?.dark_gray_box_3?.title?.bold_red_part_2 }}</strong>
            </h3>
            <p class="ouraspirations__block__content__text">{{ data?.dark_gray_box_3?.text }}</p>
          </div>
        </article>
      <!-- GRAY BOX 4 -->
        <article class="ouraspirations__block">
          <div class="ouraspirations__block__content">
            <h3 class="ouraspirations__block__content__title">
                <span>{{ data?.light_gray_box_4?.title?.red_part_1 }}</span>
                <span>&nbsp;</span>
                <strong>{{ data?.light_gray_box_4?.title?.bold_red_part_2 }}</strong>
            </h3>
            <p class="ouraspirations__block__content__text">{{ data?.light_gray_box_4?.text }}</p>
          </div>
        </article>
      <!-- GRAY BOX 5? -->
        <article *ngIf="data?.light_gray_box_5?.title?.red_part_1 || data?.light_gray_box_5?.title?.bold_red_part_2 || data?.light_gray_box_5?.image" class="ouraspirations__block">
            <div [class]="data?.light_gray_box_5?.image ? 'ouraspirations__block__content_image' : 'ouraspirations__block__content'">
                <h3 *ngIf="data?.light_gray_box_5?.title?.red_part_1 || data?.light_gray_box_5?.title?.bold_red_part_2"
                    [class]="data?.light_gray_box_5?.image ? 'ouraspirations__block__content_image__title' : 'ouraspirations__block__content__title'">
                    <span>{{ data?.light_gray_box_5?.title?.red_part_1 }}</span>
                    <span>&nbsp;</span>
                    <strong>{{ data?.light_gray_box_5?.title?.bold_red_part_2 }}</strong>
                </h3>
                <p *ngIf="data?.light_gray_box_5?.text"
                   [class]="data?.light_gray_box_5?.image ? 'ouraspirations__block__content_image__text' : 'ouraspirations__block__content__text'">
                    {{ data?.light_gray_box_5?.text }}
                </p>
                <img *ngIf="data?.light_gray_box_5?.image" [src]="data?.light_gray_box_5?.image"
                     [class]="data?.light_gray_box_5?.image ? 'ouraspirations__block__content_image__image' : 'ouraspirations__block__content__image'" alt="Image" />
            </div>
        </article>
      <!-- IMAGE BOX -->
        <article *ngIf="data?.light_gray_box_6?.title?.red_part_1 || data?.light_gray_box_6?.title?.bold_red_part_2 || data?.light_gray_box_6?.image" class="ouraspirations__block">
            <div [class]="data?.light_gray_box_6?.image ? 'ouraspirations__block__content_image' : 'ouraspirations__block__content'">
                <h3 *ngIf="data?.light_gray_box_6?.title?.red_part_1 || data?.light_gray_box_6?.title?.bold_red_part_2"
                    [class]="data?.light_gray_box_6?.image ? 'ouraspirations__block__content_image__title' : 'ouraspirations__block__content__title'">
                    <span>{{ data?.light_gray_box_6?.title?.red_part_1 }}</span>
                    <span>&nbsp;</span>
                    <strong>{{ data?.light_gray_box_6?.title?.bold_red_part_2 }}</strong>
                </h3>
                <p *ngIf="data?.light_gray_box_6?.text"
                   [class]="data?.light_gray_box_6?.image ? 'ouraspirations__block__content_image__text': 'ouraspirations__block__content__text'">
                    {{ data?.light_gray_box_6?.text }}
                </p>
                <div class="imgContainer">
                  <img *ngIf="data?.light_gray_box_6?.image" [src]="data?.light_gray_box_6?.image"
                       [class]="data?.light_gray_box_6?.image ? 'ouraspirations__block__content_image__image': 'ouraspirations__block__content__image'" alt="Image" />
                </div>
            </div>
        </article>
    </section>
  </div>
</section>
