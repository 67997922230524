<section class="productTemplate">
  <header class="productTemplate__heading">
    <section class="productTemplate__heading__inner">
      <article class="productTemplate__heading__content">
        <p class="productTemplate__heading__content__pretitle">
            {{ data?.header?.pretitle }}
        </p>
        <h1 class="productTemplate__heading__content__title">
          <strong>{{ data?.header?.post_title?.bold_part_1 }}</strong>
          <span>&nbsp;</span>
          <span>{{ data?.header?.post_title?.part_2 }}</span>
        </h1>
      </article>
      <figure class="productTemplate__heading__icon">
          <img src="{{ data?.header?.img }}" class="productTemplate__heading__icon__img" alt="Product heading image">
      </figure>
    </section>
  </header>

  <section class="productTemplate__inner">
    <div class="productTemplate__intro">
      <article class="productTemplate__intro__content">
        <p class="productTemplate__intro__content__big">
          <span>{{ data?.content?.text_1?.part_1 }}</span>
          <span>&nbsp;</span>
          <em>{{ data?.content?.text_1?.red_part_2 }}</em>
          <span>&nbsp;</span>
          <span>{{ data?.content?.text_1?.part_3 }}</span>
        </p>
        <p>
          <span>{{ data?.content?.text_2?.part_1 }}</span>
          <span>&nbsp;</span>
          <strong>{{ data?.content?.text_2?.bold_part_2 }}</strong>
          <span>&nbsp;</span>
          <span>{{ data?.content?.text_2?.part_3 }}</span>
        </p>
      </article>
      <figure class="productTemplate__intro__image">
        <img src="{{ data?.content?.img }}" class="productTemplate__intro__image__img" alt="Product intro Image">
      </figure>
    </div>

    <section class="productTemplate__list">
      <h2 class="productTemplate__list__title">
        <span>{{ data?.content?.list_title?.part_1 }}</span>
        <span>&nbsp;</span>
        <strong>{{ data?.content?.list_title?.bold_part_2 }}</strong>
        <span>&nbsp;</span>
        <span>{{ data?.content?.list_title?.part_3 }}</span>
      </h2>
      <div class="productTemplate__list__wrap">
        <article class="productTemplate__list__item" *ngFor="let item of itemList">
          <img *ngIf="item?.img" src="{{ item?.img }}" class="productTemplate__list__item__icon" alt="Product list Image">
          <p *ngIf="item?.text" class="productTemplate__list__item__text">{{ item?.text }}</p>
        </article>
      </div>

      <article>
        <p *ngIf="data?.content?.text_3?.part_1">
          {{ data?.content?.text_3?.part_1 }}
        </p>
        <p *ngIf="data?.content?.text_3?.bold_part_2">
          <strong>{{ data?.content?.text_3?.bold_part_2 }}</strong>
          <span>&nbsp;</span>
          <span>{{ data?.content?.text_3?.part_3 }}</span>
        </p>
      </article>
    </section>

  </section>
</section>
<app-block-video [data]="data?.block_video"></app-block-video>
<app-related-products [slug]="id" [data]="data?.footer"></app-related-products>
