import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-articles-two-sides',
  templateUrl: './articles-two-sides.component.html',
  styleUrls: ['./articles-two-sides.component.scss']
})
export class ArticlesTwoSidesComponent implements OnInit {
  @Input() data;

  constructor() { }

  ngOnInit() {
  }

}
