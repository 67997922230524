import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GapcalculatorService
{

  constructor(
      private api: ApiService
  ) {}

  getGapCalculator(): Observable<any>
  {
    return this.api.get('md.php?page=gapcalculator&type=pages');
  }
}
