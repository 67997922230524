import { Component, OnInit } from '@angular/core';
import { LegalService } from '../../services/legal/legal.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent implements OnInit {
  public data;

  constructor(private legalService: LegalService, private meta: Meta, private titleService: Title) { }

  ngOnInit() {
    this.legalService.getLegal().subscribe(legal => {
      this.data = (legal.length > 0) ? legal[0].acf : legal.acf;
      if (this.data.hasOwnProperty('meta_tag_title')) {
        this.titleService.setTitle(this.data.meta_tag_title.content ?? 'Legal');
      }
      if (this.data.hasOwnProperty('meta_tag_description')) {
        this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
      }
    });
  }

}
