import { Component, OnInit } from '@angular/core';
import { CareersService } from '../../services/careers/careers.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-careers01',
  templateUrl: './careers01.component.html',
  styleUrls: ['./careers01.component.scss']
})
export class Careers01Component implements OnInit
{

  public data;

  constructor(
      private careersService: CareersService,
      private meta: Meta,
      private titleService: Title
  ) {}

  ngOnInit()
  {
    this.careersService.getCareer('01').subscribe(careers => {
      this.data = (careers.length > 0) ? careers[0].acf : careers.acf;
      this.titleService.setTitle('Careers');
      this.meta.updateTag({ name: 'description', content: 'Careers' });
    });
  }

}
