import {Component, Input, OnInit} from '@angular/core';
import { HelperProvider } from '../../providers/helper.provider';

@Component({
  selector: 'app-content03',
  templateUrl: './content03.component.html',
  styleUrls: ['./content03.component.scss']
})
export class Content03Component implements OnInit {
  @Input() data;

  constructor(
      public helper: HelperProvider
  ) { }

  ngOnInit() {
  }

}
