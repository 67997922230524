<section *ngIf="data?.content || currentPage==='cookies'" class="page" [innerHTML]="data?.content ?? ''"></section>
<section *ngIf="data?.pdf_download?.section_title || data?.pdf_download?.download_box?.title" class="page mac-download">
    <div class="mac-download__inner">
        <h2 *ngIf="data?.pdf_download?.section_title" class="section-title">
            {{ data?.pdf_download?.section_title }}
        </h2>
        <article *ngIf="data?.pdf_download?.download_box?.title" class="mac-download__box">
            <h3 *ngIf="data?.pdf_download?.download_box?.title" class="mac-download__box__title">
                <img *ngIf="data?.pdf_download?.download_box?.img" [src]="data?.pdf_download?.download_box?.img" alt="Download Image" />
                <span>{{ data?.pdf_download?.download_box?.title }}</span>
            </h3>
            <div class="mac-download__inputgroup">
                <div class="fp-dropdown">
                    <button name="button_page_open_list" (click)="openList($event)" class="fp-dropdown__btn" type="button">
					  <span class="fp-dropdown__btn__text">
						<img src="../../../assets/icons/down-icon.png"  alt="Icon doc" style="width: 30px;"/>
                          <span>{{ selectedFileDownload ? selectedFileDownload?.name : data?.pdf_download?.download_box?.title }}</span>
					  </span>
                        <img src="../../../assets/icons/down-icon.png" class="fp-dropdown__btn__asset"  alt="Icon Down black" style="width: 30px;"/>
                    </button>
                    <ul class="fp-dropdown__list">
                        <li *ngFor="let item of [].constructor(getLengthObjectKeys(data?.pdf_download?.download_box?.files)); let i = index">
                            <button *ngIf="data?.pdf_download?.download_box?.files['file_' + (i+1)]?.name"
                                    (click)="selectOption($event, data?.pdf_download?.download_box?.files['file_' + (i+1)], 'selectedFileDownload')"
                                    class="fp-dropdown__opt" type="button" name="button_page_pdf_download">
                                <img src="../../../assets/icons/down-icon.png"  alt="Icon doc" style="width: 30px;"/>
                                <span>{{ data?.pdf_download?.download_box?.files['file_' + (i+1)]?.name }}</span>
                            </button>
                        </li>
                    </ul>
                </div>
                <button *ngIf="data?.pdf_download?.text_button" class="mac-download__button btn btn--blue" type="button" name="mac-download__button" (click)="downloadFile('selectedFileDownload')">
                    {{ data?.pdf_download?.text_button }}
                </button>
            </div>
        </article>
    </div>
</section>
