<section class="news01">
  <section class="news">
    <article class="new-featured">
      <div class="new-featured__inner">
        <div *ngIf="newsList?.length > 0" class="new-featured__content">
          <div class="new-featured__content__image">
            <img *ngIf="newsList[0]?.background_img" src="{{ newsList[0]?.background_img }}" class="new-featured__content__image__img" alt="Featured content Image">
            <time *ngIf="newsList[0]?.wp_modified_date" class="new-featured__content__image__date">{{ newsList[0]?.wp_modified_date }}</time>
          </div>
          <div *ngIf="newsList[0]?.title" class="new-featured__content__title" [innerHTML]="newsList[0]?.title"></div>
          <div class="new-featured__content__text">
            <div *ngIf="newsList[0]?.subtitle" [innerHTML]="newsList[0]?.subtitle"></div>
            <a *ngIf="newsList[0]?.slug" [routerLink]="['/corporate-news',  newsList[0]?.slug ]" class="link" title="Corporate News" rel="noopener noreferrer">
            <span class="link__text">
              {{ newsList[0]?.link_text }}
            </span>
            </a>
          </div>
        </div>
      </div>
    </article>

    <article class="new-front" *ngFor="let newsItem of newsList | slice:1">
      <section class="new-front__inner">
        <div class="new-front__image">
          <div *ngIf="newsItem?.background_img" class="new-front__image__img" [ngStyle]="{'background-image': 'url(' + newsItem?.background_img + ')'}"></div>
          <time *ngIf="newsItem?.wp_modified_date" class="new-front__image__date">{{ newsItem?.wp_modified_date }}</time>
        </div>
        <div class="new-front__content">
          <div *ngIf="newsItem?.title" class="new-front__content__title" [innerHTML]="newsItem?.title"></div>
          <div class="new-front__content__text">
            <div *ngIf="newsItem?.subtitle" [innerHTML]="newsItem?.subtitle"></div>
            <a *ngIf="newsItem?.slug" [routerLink]="['/corporate-news', newsItem?.slug ]" class="link" title="Corporate News" rel="noopener noreferrer">
            <span class="link__text">
              {{ newsItem?.link_text }}
            </span>
            </a>
          </div>
        </div>
      </section>
    </article>
  </section>

  <section class="news_more">
    <div class="news_more__inner">
      <a class="link news_more__show-all" href="#" (click)="showAll($event)" [ngClass]="{'d-none': hideShowMoreLink }" title="Show All" rel="noopener noreferrer">
        <span class="link__text">Show all</span>
      </a>
    </div>
  </section>
</section>
