import {Component, OnChanges, OnInit, SimpleChange, SimpleChanges} from '@angular/core';
import { CovidService } from '../../services/covid/covid.service';
import { ContactService } from '../../services/contact/contact.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
@Component({
    selector: 'app-product',
    templateUrl: './covidPost.component.html',
    styleUrls: ['./covidPost.component.scss'],
styles: [`
   .d-none {
      display: block!important;
   }
`],
})
export class CovidPostComponent implements OnInit
{
    data;
    itemList;
    id;
    headerImg;
    title;
    sub_title;
    button_text;
    content;
    country;
    target;
    locations;
    clickedMarker;
    openProductsInfo;
    public pdfDownload;
    public selectedFileDownload: any;

    constructor(
        private contactService: ContactService,
        private covidService: CovidService,
        private route: ActivatedRoute,
        private router: Router,
        private meta: Meta,
        private titleService: Title
    ) {}
    
    onNavigate(event: any)
    {
        this.router.navigate([event.target.value]);
    }

    ngOnInit()
    {
        this.route.params.subscribe(
            (params: any) => {
                this.id = params['id'];
                
                    // Pedimos la lista de paises
                this.contactService.getContact().subscribe(contact => {
                      // this.data = JSON.parse(contact.acf);
                      this.data = (contact.length > 0) ? contact[0].acf : contact.acf;
                      // SEO Tags
                    if (this.data.hasOwnProperty('meta_tag_title')) {
                        this.titleService.setTitle(this.data.meta_tag_title.content ?? 'Contact Covid');
                    }
                    if (this.data.hasOwnProperty('meta_tag_description')) {
                        this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
                    }
                      const obj = this.data.header.map_location;

                      // Adaptamos la lista de nombres de la url con su nombre completo para que se llame = que en el servicio de Countries
                      switch (params['id']) {
                            
                            case 'no': this.country = 'Norway'; break;
                            case 'be': this.country = 'Belgium'; break;
                            case 'nl': this.country = 'Netherlands'; break;
                            case 'es': this.country = 'Spain'; break;
                            case 'it': this.country = 'Italy'; break;
                            case 'at': this.country = 'Austria'; break;
                            case 'se': this.country = 'Sweden'; break;
                            case 'fi': this.country = 'Finland'; break;
                            case 'dk': this.country = 'Denmark'; break;
                            case 'de': this.country = 'Germany'; break;
                            case 'pl': this.country = 'Poland'; break;
                            case 'pt': this.country = 'Portugal'; break;
                            default: this.country = 'none'; break;
                      }
                      
                      this.locations = Object.keys(obj).map(e => obj[e]);

                      for (let i = this.locations.length - 1; i >= 0; --i) {
                           // tslint:disable-next-line:triple-equals
                           if (this.locations[i].country != this.country) {
                               this.locations.splice(i, 1);
                           }
                       }
                  
                
                      this.route.queryParams
                        .subscribe((p: any) => {
                          if ( Object.keys(p).length > 0 ) {
                            setTimeout(() => {
                              this.clickedMarker(null, p);
                              this.openProductsInfo(p);
                            }, 1000);
                          }
                        });
                    });
                    
                
                
                // Contenido de articulo
                this.covidService.getItems(this.id).subscribe(news => {
                    this.data = (news.length > 0) ? news[0].acf : news.acf;

                    // HTML Vars
                    this.headerImg = this.data.header.img;
                    this.title = this.data.content.post_title;
                    this.sub_title = this.data.content.post_subtitle;
                    this.content = this.data.content.content;
                    this.button_text = this.data.content.button_text;
                    this.pdfDownload = this.data.pdf_download;

                    // SEO Tags
                    if (this.data.hasOwnProperty('meta_tag_title')) {
                        this.titleService.setTitle(this.data.meta_tag_title.content ?? 'Contact Covid');
                    }
                    if (this.data.hasOwnProperty('meta_tag_description')) {
                        this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
                    }
                });
            }
        );
    }

    closedWindowHandler(event: any)
    {
        console.log(event);
    }

    openList($event)
    {
        const button = ( $event.target.tagName == 'BUTTON' ) ? $event.target : $event.target.parentNode;
        const list = button.nextSibling;
        if ( list.classList.contains('open') ) {
            list.classList.remove('open');
        } else {
            list.classList.add('open');
        }
    }

    selectOption($event, product: any, variableSelected: string)
    {
        const button = ( $event.target.tagName === 'BUTTON' ) ? $event.target : $event.target.parentNode;
        const list = button.parentNode.parentNode;
        const inputGroup = list.parentNode;
        const buttonSelected = inputGroup.children[0];
        buttonSelected.children[0].innerHTML = button.innerHTML;
        list.classList.remove('open');

        this[variableSelected] = product;
    }

    downloadFile(variableSelected: string)
    {
        if (this[variableSelected]) {
            window.open(this[variableSelected].file, '_blank');
        }
    }

    getLengthObjectKeys(object: {})
    {
        if (typeof object === 'object' && object !== undefined && object !== null) {
            return Object.keys(object).length;
        }

        return 0;
    }

}
