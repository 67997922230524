import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, Output} from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-contact-products',
  templateUrl: './contact-products.component.html',
  styleUrls: ['./contact-products.component.scss']
})
export class ContactProductsComponent implements OnChanges {

  @Input() data;
  products;

  @Output() infoClosed = new EventEmitter<boolean>();

  constructor(private elRef: ElementRef) { }

  ngOnChanges(changes: any)
  {
    if (changes.hasOwnProperty('data')) {
      if (this.data && this.data.hasOwnProperty('products')) {
        this.products = Object.keys(this.data.products).map(e => this.data.products[e]);
      }
    }
  }

  closeInfo(event)
  {
    event.preventDefault();
    const infoContent = this.elRef.nativeElement.querySelector('#contact-country');
    infoContent.classList.add('d-none');
    this.infoClosed.emit(true);
  }
}
