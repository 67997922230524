<div class="text-image">
  <div class="text-image__inner">
    <div class="text-image__content">
      <h2 class="text-image__content__title">
        {{ data.content_1.title }}
      </h2>
      <div class="text-image__content__text">
        <p>
          {{ data.content_1.text.part_1['part_1-1'] }}
          <strong>{{ data.content_1.text.part_1['bold_part_1-2'] }}</strong>
        </p>
        <p>
          {{ data.content_1.text.part_2 }}
        </p>
        <p>
          <a [routerLink]="['/careers/workwithus']" class="link" rel="noopener noreferrer">
            <span class="link__text">{{ data.content_1.text.link_text }}</span>
          </a>
        </p>
      </div>
    </div>
    <div class="text-image__image">
      <img src="{{ data.content_1.img }}" class="text-image__image__img" alt="Article Image" >
    </div>
  </div>
</div>
  
<div class="text-image text-image--reverse">
  <div class="text-image__inner">
    <div class="text-image__content">
      <h2 class="text-image__content__title">
        {{ data.content_2.title }}
      </h2>
      <div class="text-image__content__text">
        <p>
          {{ data.content_2.text.part_1 }}
        </p>
        <p>
            {{ data.content_2.text.part_2 }}
        </p>
      </div>
    </div>
    <div class="text-image__image">
      <img src="{{ data.content_2.img }}" class="text-image__image__img"  alt="Article Image">
    </div>
  </div>
</div>
