<section class="error">
  <div class="error__inner">
    <h1 class="error__title">404</h1>
    <h2 class="error__subtitle">Opps! Something went wrong,</h2>
    <article class="error__text">
      <p>sorry for any inconvenience.</p>
    </article>
    <a [routerLink]="['/home']" class="btn btn--red" title="Go to Home" rel="noopener noreferrer">
      Back to home
      <img src="../assets/icons/right-white-icon.svg" class="btn__asset" alt="Icon right">
    </a>
  </div>
</section>
