import { Component, OnInit } from '@angular/core';
import { AboutService } from '../../services/about/about.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { HelperProvider } from '../../providers/helper.provider';

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit
{

    public data;

    constructor(
        private aboutService: AboutService,
        private meta: Meta,
        private titleService: Title,
        public helper: HelperProvider
    ) {}

    ngOnInit()
    {
        this.aboutService.getAboutNewPage().subscribe((about: any) => {
            this.data = (about.length > 0) ? about[0].acf : about.acf;

            if (this.data) {
                if (this.data.hasOwnProperty('meta_tag_title')) {
                    this.titleService.setTitle(this.data.meta_tag_title.content ?? 'About Us');
                }
                if (this.data.hasOwnProperty('meta_tag_description')) {
                    this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
                }
                if (this.data.hasOwnProperty('meta_tag_title')) {
                    this.titleService.setTitle(this.data.meta_tag_title.content ?? 'About Us');
                }
            }

        });
    }

}
