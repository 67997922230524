<section *ngIf="data">
  <button type="button" name="button_close_job" class="close" aria-label="Close" (click)="modal?.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>

  <h2 class="modal__title">{{ data?.title }}</h2>
  <p class="modal__location mb-5">
    <img src="../../../assets/icons/location-icon.png" class="modal__location__img" alt="Location Image"/>
    <span>{{ data?.location?.city }}, {{ data?.location?.country }}</span>
  </p>

  <article class="modal__text">
    <p *ngIf="data?.date" class="mb-2">
      <strong>Date created:</strong>
      <span>&nbsp;</span>
      <span>{{ data?.date }}</span>
    </p>
    <p *ngIf="data?.type" class="mb-2">
      <strong>Type:</strong>
      <span>&nbsp;</span>
      <span>{{ data?.type }}</span>
    </p>
    <p *ngIf="data?.accountable_to" class="mb-2">
      <strong>Accountable to:</strong>
      <span>&nbsp;</span>
      <span>{{ data?.accountable_to }}</span>
    </p>
    <p *ngIf="data?.people_manager" class="mb-2">
      <strong>People Manager:</strong>
      <span>&nbsp;</span>
      <span>{{ data?.people_manager }}</span>
    </p>

    <p *ngIf="data?.description?.part_1" class="mb-2 mt-5">
      <strong>Summary of Role:</strong>
    </p>

    <p *ngIf="data?.description?.part_1" class="mb-2">
      {{ data?.description?.part_1 }}
    </p>
    <hr />
    <div *ngIf="data?.description?.part_2" class="mb-2" [innerHTML]="data?.description?.part_2"></div>
    <p *ngIf="data?.description?.part_3" class="mb-2">
      {{ data?.description?.part_3 }}
    </p>

    <!-- Responsability Main -->
    <p *ngIf="data?.description_key_responsibilities_text" class="mb-2 mt-5">
      <strong>{{ data?.description_key_responsibilities_text }}</strong>
    </p>

    <ul *ngIf="responsabilityList">
      <li *ngIf="data?.description_key_responsibilities_list?.list_item_1_text">
        <span>{{ data?.description_key_responsibilities_list?.list_item_1_text }}</span>
        <ul *ngIf="responsabilityListItem1">
          <div *ngFor="let item of responsabilityListItem1">
            <li *ngIf="item && item?.constructor?.name == 'String'">
              {{ item }}
            </li>
          </div>
        </ul>
      </li>
      <li *ngIf="data?.description_key_responsibilities_list?.list_item_2_text">
        <span>{{ data?.description_key_responsibilities_list?.list_item_2_text }}</span>
        <ul *ngIf="responsabilityListItem2">
          <div *ngFor="let item of responsabilityListItem2">
            <li *ngIf="item && item?.constructor?.name == 'String'">
              {{ item }}
            </li>
          </div>
        </ul>
      </li>

      <div *ngFor="let item of responsabilityList">
        <li *ngIf="item && item?.constructor?.name == 'String'">
          {{ item }}
        </li>
      </div>
    </ul>

    <p *ngIf="respAloneItem">{{ respAloneItem }}</p>

    <!-- Competencies Main -->
    <p *ngIf="data?.description_role_competencies_text" class="mb-2">
      <strong>{{ data?.description_role_competencies_text }}</strong>
    </p>

    <ul *ngIf="competenciesList" class="mb-5">
      <div *ngFor="let item of competenciesList">
        <li *ngIf="item && item?.constructor?.name == 'String'">
          {{ item }}
        </li>
      </div>
    </ul>

    <!-- Competencies List 1 -->
    <p *ngIf="data?.description_role_competencies_text?.list_item_text" class="mb-2">
      {{ data?.description_role_competencies_text?.list_item_text }}
    </p>

    <ul *ngIf="competenciesListItem1" class="mb-5">
      <div *ngFor="let item of competenciesListItem1">
        <li *ngIf="item && item?.constructor?.name == 'String'">
          {{ item }}
        </li>
      </div>
    </ul>

    <!-- Competencies Behavioural -->
    <p *ngIf="competenciesBehaviouralText" class="mb-2">
      {{ competenciesBehaviouralText }}
    </p>

    <ul *ngIf="competenciesBehavioural" class="mb-5">
      <div *ngFor="let item of competenciesBehavioural">
        <li *ngIf="item && item?.constructor?.name == 'String'">
          {{ item }}
        </li>
      </div>
    </ul>

    <!-- Competencies Technical -->
    <p *ngIf="competenciesTechnicalText" class="mb-2">
      {{ competenciesTechnicalText }}
    </p>

    <ul *ngIf="competenciesTechnical" class="mb-5">
      <div *ngFor="let item of competenciesTechnical">
        <li *ngIf="item && item?.constructor?.name == 'String'">
          {{ item }}
        </li>
      </div>
    </ul>

    <!-- Requirements Main -->

    <ul *ngIf="list" class="mb-5">
      <div *ngFor="let item of list">
        <li *ngIf="item && item?.constructor?.name == 'String'">
          <span>{{ item }}</span>
        </li>
        <li *ngIf="item && item?.constructor?.name == 'Object' && (item.item_1 != '' || item.text != '')">
          <span *ngIf="item?.item_1 && item?.item_1?.constructor?.name == 'String'">
            {{ item.item_1 }}
            <br/>
          </span>
          <span *ngIf="item?.item_2 && item?.item_2?.constructor?.name == 'String'">
            {{ item.item_2 }}
            <br/>
          </span>
          <span *ngIf="item?.item_3 && item?.item_3?.constructor?.name == 'String'">
            {{ item.item_3 }}
            <br/>
          </span>
          <span *ngIf="item.text && item?.text?.constructor?.name == 'String'">
            {{  item.text }}
          </span>
        </li>
      </div>
    </ul>

    <h3 class="modal__subtitle mb-1">{{ data?.apply_now_text }}</h3>

    <p *ngIf="data?.send_email_text?.red_email_part_2">
      <span>{{ data?.send_email_text?.part_1 }}</span>
      <span>&nbsp;</span>
      <a href="mailto:{{ data?.send_email_text?.red_email_part_2 }}" class="link" rel="noopener noreferrer">
        <span class="link__text">
          {{ data?.send_email_text?.red_email_part_2 }}
        </span>
      </a>
    </p>

    <p *ngIf="data?.visit_our_linkedin" class="mb-2">
      <a class="link" target="_blank" href="{{ data?.visit_our_linkedin.link }}" rel="noopener noreferrer">
        <span class="link__text">
          {{ data?.visit_our_linkedin?.hyperlink_text }}
        </span>
      </a>
    </p>

    <p>CNP Santander is an equal opportunities employer.</p>
  </article>
</section>
