import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LegalService
{

  constructor(private api: ApiService) {}

  getLegal(): Observable<any>
  {
    return this.api.get('md.php?page=legal&type=pages');
  }

  getPrivacy(): Observable<any>
  {
    return this.api.get('md.php?page=privacy&type=pages');
  }

  getPrivacyPolicies(): Observable<any>
  {
    return this.api.get('md.php?page=privacy-policies&type=pages');
  }

  getTerms(): Observable<any>
  {
    return this.api.get('md.php?page=terms-and-conditions&type=pages');
  }
}
