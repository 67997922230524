import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-content-jobs-link02',
  templateUrl: './content-jobs-link02.component.html',
  styleUrls: ['./content-jobs-link02.component.scss']
})
export class ContentJobsLink02Component implements OnInit {

  @Input() data;

  constructor() { }

  ngOnInit() {
  }

}
