import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-our-values',
  templateUrl: './our-values.component.html',
  styleUrls: ['./our-values.component.scss']
})
export class OurValuesComponent implements OnInit {
  @Input() data;

  constructor() { }

  ngOnInit() {
  }

}
