import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';
import { HelperProvider } from './providers/helper.provider';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {

  constructor(
      public router: Router,
      public helperProvider: HelperProvider
  ) { }

  ngOnInit()
  {
    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        window.scrollTo(0, 0);

        if (e.id !== 1) {
          setTimeout(() => {
            this.helperProvider.refreshReachDeckToolbar();
          }, 1500);
        }
      }
    });

    document.addEventListener('refresh-reach-deck', (data) => {
      setTimeout(() => {
        this.helperProvider.refreshReachDeckToolbar();
      }, 1000);
    });

    setTimeout(() => {
      this.helperProvider.listenClickEventSimplifyReachDeck();
    }, 4000);
  }

  ngAfterViewInit()
  {
    setTimeout(() => {
      this.helperProvider.loadReachDeckScript().then(() => {
        this.helperProvider.reachDeckBottomToolbar();
        this.helperProvider.disabledBrowsealoudAnalytics();
      });
    }, 3000);
  }
}
