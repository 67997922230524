import { Component, OnInit } from '@angular/core';
import {HomeService} from '../../services/home/home.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit
{

  public data;
  public products = [];
  public news = [];
  public countries = [];

  constructor(
      public homeService: HomeService,
      public meta: Meta,
      public titleService: Title
  ) {}

  ngOnInit()
  {
    this.homeService.getHome().subscribe(home => {
      this.data = (home.length > 0) ? home[0].acf : home.acf;
      this.news = JSON.parse(this.data.content.news_box.news_list).filter((item, index) => index < 2);
      this.products = JSON.parse(this.data.content.products_box.products_title_list);

      const obj = this.data.content.contact_us_box.countries;
      this.countries = Object.keys(obj).map(e => obj[e]);

      if (this.data.hasOwnProperty('meta_tag_title')) {
        this.titleService.setTitle(this.data.meta_tag_title.content ?? 'Home');
      }
      if (this.data.hasOwnProperty('meta_tag_description')) {
        this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
      }
    });
  }

  scrollTo(id)
  {
    const el = document.getElementById(id);
    let actualScroll = window.pageYOffset;
    // Scroll to the middle of the hero height (100vh)
    const scrollInterval = setInterval(() => {
      if (actualScroll >= window.innerHeight / 2 ) {
        clearInterval(scrollInterval);
      } else {
        actualScroll += 10;
        window.scrollTo(0, actualScroll);
      }
    }, 10);
  }
}
