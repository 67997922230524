import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class AboutService
{

  constructor(private api: ApiService) {}

  getAbout(page = 1): Observable<any>
  {
    return this.api.get('md.php?page=about0' + page + '&type=pages');
  }

  getAboutNewPage(): Observable<any>
  {
    return this.api.get('md.php?page=about-us-new&type=pages');
  }
}
