import {Component, OnInit} from '@angular/core';
import { CareersService } from '../../services/careers/careers.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-careers02',
    templateUrl: './careers02.component.html',
    styleUrls: ['./careers02.component.scss']
})
export class Careers02Component implements OnInit
{

    public data;

    constructor(
        private careersService: CareersService,
        private meta: Meta, private titleService: Title
    ) { }

    ngOnInit()
    {
        this.careersService.getCareer('02').subscribe(careers => {
            this.data = (careers.length > 0) ? careers[0].acf : careers.acf;
            if (this.data.hasOwnProperty('meta_tag_title')) {
                this.titleService.setTitle(this.data.meta_tag_title.content ?? 'Careers');
            }
            if (this.data.hasOwnProperty('meta_tag_description')) {
                this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
            }
        });
    }

}
