import { Component, OnInit } from '@angular/core';
import { LegalService } from '../../services/legal/legal.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit
{

  public data;
  public list51;
  public list52;
  public list61;

  constructor(
      private legalService: LegalService,
      private meta: Meta,
      private titleService: Title
  ) { }

  ngOnInit()
  {
    this.legalService.getTerms().subscribe(legal => {
      this.data = (legal.length > 0) ? legal[0].acf : legal.acf;
      if (this.data.hasOwnProperty('meta_tag_title')) {
        this.titleService.setTitle(this.data.meta_tag_title.content ?? 'Terms and Conditions');
      }
      if (this.data.hasOwnProperty('meta_tag_description')) {
        this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
      }
      const obj = this.data.content.text_5.list_5_1;
      this.list51 = Object.keys(obj).map(e => obj[e]);
      const obj2 = this.data.content.text_5.list_5_2;
      this.list52 = Object.keys(obj2).map(e => obj2[e]);
      const obj3 = this.data.content.text_6.list_6_1;
      this.list61 = Object.keys(obj3).map(e => obj3[e]);
    });
  }

}
