<section class="news">
    <div *ngIf="current?.background_img" class="news__image" [ngStyle]="{'background-image': 'url(' + current?.background_img + ')'}"></div>

    <section *ngIf="prevNew?.slug || nextNew?.slug" class="news__navigation">
        <div class="news__navigation__inner">
            <a [routerLink]="['/corporate-news/',  prevNew?.slug ]" class="news__navigation__prev" [ngClass]="{ disabled: prevNew === false }" title="Corporate News Previous" rel="noopener noreferrer">
                <span class="news__navigation__prev__arrow"></span>
            </a>
            <a [routerLink]="['/corporate-news/',  nextNew?.slug ]" class="news__navigation__next" [ngClass]="{ disabled: nextNew === false }" title="Corporate News Next" rel="noopener noreferrer">
                <span class="news__navigation__next__arrow"></span>
            </a>
        </div>
    </section>

    <section class="news__content">
        <section class="news__content__inner">
            <div *ngIf="current?.title" class="news__content__title" [innerHTML]="current?.title"></div>
            <div *ngIf="current?.wp_modified_date" class="news__content__date" [innerHTML]="current?.wp_modified_date"></div>
            <div *ngIf="current?.subtitle" class="news__content__intro" [innerHTML]="current?.subtitle"></div>
            <div *ngIf="current?.first_text" class="news__content__text" style="margin-bottom: 25px" [innerHTML]="current?.first_text"></div>

            <div *ngIf="current?.first_left_column || current?.first_right_column" class="text-2cols">
                <div class="text-2cols__inner">
                    <div *ngIf="current?.first_left_column" class="text-2cols__col" [innerHTML]="current?.first_left_column"></div>
                    <div *ngIf="current?.first_right_column" class="text-2cols__col" [innerHTML]="current?.first_right_column"></div>
                </div>
            </div>

            <div *ngIf="current?.quote?.hashtag || current?.quote?.text" class="quote">
                <div class="quote__inner">
                    <div *ngIf="current?.quote?.hashtag" class="quote__hashtag" [innerHTML]="current?.quote?.hashtag"></div>
                    <div *ngIf="current?.quote?.text" class="quote__text" [innerHTML]="current?.quote?.text"></div>
                </div>
            </div>

            <div *ngIf="current?.second_text" class="news__content__text">
                <img *ngIf="current?.second_text_img" src="{{ current?.second_text_img }}" class="w-100 mb-5" alt="News Image">
                <div [innerHTML]="current?.second_text"></div>
            </div>

            <div *ngIf="current?.second_left_column || current?.second_right_column" class="text-2cols">
                <div class="text-2cols__inner">
                    <div *ngIf="current?.second_left_column" class="text-2cols__col" [innerHTML]="current?.second_left_column"></div>
                    <div *ngIf="current?.second_right_column" class="text-2cols__col" [innerHTML]="current?.second_right_column"></div>
                </div>
            </div>
        </section>
    </section>
</section>
