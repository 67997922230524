import { Component, OnInit, Input } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss']
})
export class JobDetailComponent implements OnInit
{

  @Input() data;

  public list;
  public competenciesList;
  public competenciesListItem1;
  public competenciesTechnicalText;
  public competenciesTechnical;
  public competenciesBehaviouralText;
  public competenciesBehavioural;
  public responsabilityList;
  public responsabilityListItem1;
  public responsabilityListItem2;
  public respAloneItem;

  constructor(
      private modalService: NgbModal
  ) {}

  ngOnInit()
  {
    if (!this.data) {
      return false;
    }

    const compList = this.data.description_role_competencies_list;
    this.competenciesTechnicalText = compList.technical.text;
    this.competenciesBehaviouralText = compList.behavioural_competencies.text;
    this.competenciesListItem1 = Object.keys(compList.list_item).map(e => compList.list_item[e]);
    this.competenciesTechnical = Object.keys(compList.technical).map(e => compList.technical[e]);
    this.competenciesBehavioural = Object.keys(compList.behavioural_competencies).map(e => compList.behavioural_competencies[e]);
    this.competenciesList = Object.keys(compList).map(e => compList[e]);

    this.competenciesTechnical.pop();
    this.competenciesBehavioural.pop();

    const respList = this.data.description_key_responsibilities_list;

    this.responsabilityListItem1 = Object.keys(respList.list_item_1).map(e => respList.list_item_1[e]).reverse();
    this.responsabilityListItem2 = Object.keys(respList.list_item_2).map(e => respList.list_item_2[e]).reverse();
    this.responsabilityList = Object.keys(respList).map((e, idx) => {
      if (e.indexOf('list_item') === -1) {
        return respList[e];
      }
    }).reverse();
  }

}
