<div class="categoryTemplate">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 nopadding">
        <div class="bgHeaderCategory">
          <div class="container">
            <div class="row">
              <div class="col-lg-1 hidden-md hidden-xs"></div>
              <div class="col-lg-11 col-md-12 col-xs-12">
                <div class="titleCategoryContainer">
                  <h1 class="titleCategory">Corporate news</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-xs-12">

        <!-- ngFor article - adding featured class to index 0 -->
        <article class="articleCategoryContainer featured">
          <div class="metaContainer">
            <img class="imageArticle" src="../assets/images/mainArticleCategory.jpg" alt="Article Category Image">
          </div>
          <div class="infoArticle">
            <h1 class="titleArticle">Staff members get involved in the ‘Time to Read’ programme.</h1>
            <p class="excerptArticle">A number of <span class="markText">CNP Santander Insurance</span> employees have signed up for the ‘Time to Read’ programme run by Business in the Community Ireland.</p>
            <a class="linkToArticle" href="#">Learn more</a>
          </div>
        </article>
        <article class="articleCategoryContainer nonFeatured">
          <div class="metaContainer">
            <img class="imageArticle" src="../assets/images/articleCategory.jpg" alt="Article Category Image">
          </div>
          <div class="infoArticle">
            <h1 class="titleArticle">Staff members get involved in the ‘Time to Read’ programme.</h1>
            <p class="excerptArticle">A number of <span class="markText">CNP Santander Insurance</span> employees have signed up for the ‘Time to Read’ programme run by Business in the Community Ireland.</p>
            <a class="linkToArticle" href="#">Learn more</a>
          </div>
        </article>
        <article class="articleCategoryContainer nonFeatured">
          <div class="metaContainer">
            <img class="imageArticle" src="../assets/images/articleCategory.jpg" alt="Article Category Image">
          </div>
          <div class="infoArticle">
            <h1 class="titleArticle">Staff members get involved in the ‘Time to Read’ programme.</h1>
            <p class="excerptArticle">A number of <span class="markText">CNP Santander Insurance</span> employees have signed up for the ‘Time to Read’ programme run by Business in the Community Ireland.</p>
            <a class="linkToArticle" href="#">Learn more</a>
          </div>
        </article>
        <article class="articleCategoryContainer nonFeatured">
          <div class="metaContainer">
            <img class="imageArticle" src="../assets/images/articleCategory.jpg" alt="Article Category Image">
          </div>
          <div class="infoArticle">
            <h1 class="titleArticle">Staff members get involved in the ‘Time to Read’ programme.</h1>
            <p class="excerptArticle">A number of <span class="markText">CNP Santander Insurance</span> employees have signed up for the ‘Time to Read’ programme run by Business in the Community Ireland.</p>
            <a class="linkToArticle" href="#">Learn more</a>
          </div>
        </article>

      </div>
    </div>
  </div>
</div>
