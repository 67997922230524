<section class="relatedProducts">
  <article class="relatedProducts__title">
    <span>{{ data?.left_title }}</span>
  </article>
  <article class="relatedProducts__product" *ngFor="let prod of productList;">
    <h3 class="relatedProducts__product__title">
      <strong>{{ prod?.TITLE_BOLD_PART_1 }}</strong>
      <span>&nbsp;</span>
      <span>{{ prod?.TITLE_PART_2 }}</span>
    </h3>
    <a [routerLink]="['/products/',  prod?.SLUG ]" class="btn btn--white" title="Product Detail" rel="noopener noreferrer">
      <span>{{ data?.button_text }}</span>
      <img src="../assets/icons/right-icon.png" class="btn__asset" alt="Icon Right Black" style="height: 29px">
    </a>
  </article>
</section>
