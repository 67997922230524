<section class="about">

  <section *ngIf="data?.header_box?.show_block_header_box || !data?.header_box?.hasOwnProperty('show_block_header_box')" class="hero">
    <div *ngIf="data?.header_box?.header_image" class="hero__bg" [ngStyle]="{'background-image': 'url(' + data?.header_box?.header_image + ')'}"></div>
    <div class="hero__inner">
      <article class="hero__content">
        <div *ngIf="data?.header_box?.logo" class="hero__content__logo">
          <img class="hero__content__logo__img" src="{{ data?.header_box?.logo }}" alt="Hero Content Image">
        </div>
        <section class="hero__content__blue">
          <h1 *ngIf="data?.header_box?.title" class="hero__content__title">{{ data?.header_box?.title }}</h1>
          <div *ngIf="data?.header_box?.text?.part_1 || data?.header_box?.text?.bold_part_2" class="hero__content__text">
            <span *ngIf="data?.header_box?.text?.part_1">
              {{ data?.header_box?.text?.part_1 }}
            </span>
            <strong *ngIf="data?.header_box?.text?.bold_part_2">
              {{ data?.header_box?.text?.bold_part_2 }}
            </strong>
          </div>
        </section>
      </article>
    </div>
  </section>

  <div *ngIf="data?.content_box_1?.show_block_content_box || !data?.content_box_1?.hasOwnProperty('show_block_content_box')">

    <section class="about-intro-text">
      <div class="about-intro-text__inner">
        <article class="about-intro-text__content">
          <p *ngIf="data?.content_box_1?.title">
            {{ data?.content_box_1?.title }}
          </p>
        </article>
      </div>
    </section>

    <section class="text-2cols">
      <div class="text-2cols__inner">
        <article class="text-2cols__col">
          <p *ngIf="data?.content_box_1?.text?.left_part_1">
            {{ data?.content_box_1?.text?.left_part_1 }}
          </p>
        </article>
        <article class="text-2cols__col">
          <p *ngIf="data?.content_box_1?.text?.right_part_2">
            {{ data?.content_box_1?.text?.right_part_2 }}
          </p>
        </article>
      </div>
    </section>
  </div>

  <section *ngIf="data?.content_box_2?.show_block_content_box || !data?.content_box_2?.hasOwnProperty('show_block_content_box')" class="text-2cols">
    <div class="text-2cols__inner">
      <article class="text-2cols__col">
        <h3 *ngIf="data?.content_box_2?.title_1" class="title big">{{ data?.content_box_2?.title_1 }}</h3>
        <p *ngIf="data?.content_box_2?.list_text">{{ data?.content_box_2?.list_text }}</p>
        <ul class="check-list">
            <span *ngFor="let item of [].constructor(helper.getLengthObjectKeys(data?.content_box_2?.list_items)); let i = index">
                <li *ngIf="data?.content_box_2?.list_items['item_' + (i+1)]?.text?.bold_part_1">
                    <strong *ngIf="data?.content_box_2?.list_items['item_' + (i+1)]?.text?.bold_part_1">
                        {{ data?.content_box_2?.list_items['item_' + (i+1)]?.text?.bold_part_1 }}
                    </strong>
                    <span>&nbsp;</span>
                    <span *ngIf="data?.content_box_2?.list_items['item_' + (i+1)]?.text?.part_2">
                        {{ data?.content_box_2?.list_items['item_' + (i+1)]?.text?.part_2 }}
                    </span>
                </li>
            </span>
        </ul>
      </article>
      <article class="text-2cols__col">
        <h3 *ngIf="data?.content_box_2?.title_2" class="title big">{{ data?.content_box_2?.title_2 }}</h3>
        <p *ngIf="data?.content_box_2?.text?.part_1">{{ data?.content_box_2?.text?.part_1 }}</p>
        <p *ngIf="data?.content_box_2?.text?.part_2">{{ data?.content_box_2?.text?.part_2 }}</p>
        <a *ngIf="data?.content_box_2?.button_text" [routerLink]="['/missionvision']"
           class="link" title="Mission & Vision" rel="noopener noreferrer">
          <span class="link__text">{{ data?.content_box_2?.button_text }}</span>
        </a>
      </article>
    </div>
  </section>

  <app-our-values
          *ngIf="data?.content_box_3.show_block_content_box || !data?.content_box_3?.hasOwnProperty('show_block_content_box')"
          [data]="data?.content_box_3">
  </app-our-values>
  <app-great-place-to-work
          *ngIf="data?.footer_1.show_block_footer || !data?.footer_1?.hasOwnProperty('show_block_footer')"
          [data]="data?.footer_1">
  </app-great-place-to-work>
  <app-products-offer
          *ngIf="data?.footer_2.show_block_footer || !data?.footer_2?.hasOwnProperty('show_block_footer')"
          [data]="data?.footer_2">
  </app-products-offer>
</section>
