<section class="careers" *ngIf="data && data.content">
  <div class="medium-title-cta">
    <div class="medium-title-cta__inner">
      <h1 *ngIf="data?.header?.title?.part_1" class="medium-title-cta__title">
        <span>{{ data?.header?.title?.part_1 }}</span>
        <span>&nbsp;</span>
        <strong *ngIf="data?.header?.title.bold_part_2">{{ data?.header?.title.bold_part_2 }}</strong>
      </h1>
      <article *ngIf="data?.header?.text" class="medium-title-cta__content">
        <div class="medium-title-cta__content__text">
          <p>
              {{ data?.header?.text }}
          </p>
        </div>
      </article>
      <a *ngIf="data?.header?.red_button_text" [routerLink]="['/jobs']" class="btn btn--red" title="Jobs" rel="noopener noreferrer">
        <!-- Contrast Failure ↓ -->
        <span>{{ data?.header?.red_button_text }}</span>
        <img src="../assets/icons/right-white-icon.svg" class="btn__asset" alt="Icon Right">
      </a>
    </div>
  </div>

  <section class="redtextimage">
    <div class="redtextimage__content">
      <div class="redtextimage__content__inner">
        <h2 *ngIf="data?.content?.title" class="redtextimage__content__title">
          {{ data?.content?.title }}
        </h2>
        <article *ngIf="data?.content?.text?.part_1 || data?.content?.text?.bold_part_2" class="redtextimage__content__text">
          <p *ngIf="data?.content?.text?.part_1">
            {{ data?.content?.text?.part_1 }}
          </p>
          <p *ngIf="data?.content?.text?.bold_part_2">
            <strong>{{ data?.content?.text?.bold_part_2 }}</strong>
          </p>
        </article>
      </div>
    </div>
    <div *ngIf="data?.content?.img" class="redtextimage__image">
        <img src="{{ data?.content?.img }}" class="redtextimage__image__img" alt="Red Text Image">
    </div>
  </section>

  <section *ngIf="data?.content?.working_with_us_title.part_1 || data?.content?.working_with_us_title.bold_part_2 || data?.content?.working_with_us_title.part_2" class="text-intro">
    <div class="text-intro__inner">
      <p>
          <span>{{ data?.content?.working_with_us_title.part_1 }} </span>
        <span>&nbsp;</span>
        <strong>{{ data?.content?.working_with_us_title.bold_part_2 }}</strong>
        <span>&nbsp;</span>
        <span>{{ data?.content?.working_with_us_title.part_2 }}</span>
      </p>
    </div>
  </section>

  <section class="text-2cols">
    <div class="text-2cols__inner">
      <article *ngIf="data?.content?.box_1?.title ||data?.content?.box_1?.text" class="text-2cols__col">
        <h3 *ngIf="data?.content?.box_1?.title" class="title">{{ data?.content?.box_1?.title }}</h3>
        <p *ngIf="data?.content?.box_1?.text">
          {{ data?.content?.box_1?.text }}
        </p>
      </article>
      <article *ngIf="data?.content?.box_2?.title || data?.content?.box_2?.text" class="text-2cols__col">
          <h3 *ngIf="data?.content?.box_2?.title" class="title">{{ data?.content?.box_2?.title }}</h3>
        <p>
          {{ data?.content?.box_2?.text }}
        </p>
      </article>
    </div>
  </section>

  <section class="benefits">
    <div class="benefits__inner">
      <h3 *ngIf="data?.content?.benefits_part?.title" class="benefits__title">
        {{ data?.content?.benefits_part?.title }}
      </h3>
      <p *ngIf="data?.content?.benefits_part?.text" class="benefits__text">
          {{ data?.content?.benefits_part?.text }}
      </p>
      <div class="benefits__wrapper">
        <app-benefit *ngFor="let benefit of benefits" [benefit]="benefit" class="benefit"></app-benefit>
      </div>
    </div>
  </section>

  <section class="text-cta">
    <div class="text-cta__inner">
      <h2 *ngIf="data?.content?.go_jobs_section_part?.title" class="text-cta__title">{{ data?.content?.go_jobs_section_part?.title }}</h2>
      <article class="text-cta__text">
        <p *ngIf="data?.content?.go_jobs_section_part?.text">
          {{ data?.content?.go_jobs_section_part?.text }}
        </p>
      </article>
      <!-- Contrast Failure ↓ -->
      <a *ngIf="data?.content?.go_jobs_section_part?.red_bottom_text" [routerLink]="['/jobs']" class="btn btn--red" title="Jobs" rel="noopener noreferrer">
        <!-- Contrast Failure ↓ -->
        <span>{{ data?.content?.go_jobs_section_part?.red_bottom_text }}</span>
        <img src="../assets/icons/right-white-icon.svg" class="btn__asset" alt="Icon Right">
      </a>
    </div>
  </section>
</section>
